@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "src/main/styles/mixins";
* {
  box-sizing: border-box;
}
.summary {
  max-width: 100%;

  &_heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: rem(1) solid map-get($color_gray, "_1");
    padding-bottom: rem(12);
    margin-bottom: rem(24);

    @media (max-width: $sm) {
      flex-direction: column;
    }

    & h1 {
      margin-bottom: rem(16);
    }

    &_subheading {
      color: map-get($color_secondary, "_0");
    }

    &_users {
      color: map-get($color_gray, "_4");
    }
  }

  &_button {
    @media (max-width: $sm) {
      margin-top: rem(16);
    }
  }

  &_table {
    width: 100%;
    table-layout: fixed;

    &_tabs {
      width: 100%;
      height: rem(48);
      display: flex;
      flex-direction: row;
      margin-bottom: rem(20);

      >button {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #4B5058;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: calc(100%/3);
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        border-left: 0;
        border-right: 0;
        background-color: map-get($color_white, '_0');

        &:hover {
           background: map-get($color_gray, "_0");
        }

        >span {
          color: map-get($color_black, "_1");
        }
      }

      .active {
        border-bottom: 2px solid map-get($color_basic, '_1');
        background-color:  map-get($color_gray, "_0");
      }
    }

    &_wrapper {
      overflow-x: auto;
      max-width: 100%;
      background-color: map-get($color_white, "_0");
      margin-top: rem(16);
      border-radius: rem(6);
      padding-bottom: rem(8);
    }

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_0");

      & th {
        text-align: start;
        font-weight: 400;
        padding: rem(20) rem(16);
      }

      &_sort {
        opacity: 0;
        color: map-get($color_gray, "_3");
        font-weight: 400;
        margin-left: rem(8);
        cursor: pointer;
        transition: 0.3s all;

        &_active {
          opacity: 1;
        }
      }

      &_arrow {
        display: flex;

        &_active {
          display: flex;
          transform: rotate(180deg);
        }
      }

      &_cell {
        >div {
          position: inherit;
          display: flex;
          align-items: center;
          transition: 0.3s all;
        }

        &:hover .summary_table_heading_sort {
          opacity: 1;
        }
      }
    }

    &_body {
      &:hover {
        transition: all .1s;
        background-color: map-get($color_gray, "_0");
        cursor: pointer;
      }

      border-bottom: rem(1) solid map-get($color_gray, "_0");

      &:last-child {
        border-bottom: none;
      }

      & td {
        text-align: start;
        padding: rem(16) rem(16);
        word-wrap: break-word;
        vertical-align: middle;
        display: table-cell;
        font-weight: 400;
        min-width: rem(160);
        max-width: rem(260);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
  
      }
    }

    & &_edit {
      width: 10%;
      min-width: rem(110);
      cursor: pointer;

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    &_iconWrapper {
      display: flex;
      @include simple-circle;

      &:hover {
        @include circle-hover;

        svg {
          fill: map-get($color_basic, '_0');
        }
      }
    }
  }
}

.unselectable {
  cursor: default !important;
  background-color: transparent !important;
}