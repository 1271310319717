@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../header/styles.scss";



.cross {
    cursor: pointer;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-list {
    margin-top: rem(12);
    display: flex;
    flex-direction: column;
    gap: rem(12);
}

.product {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(75, 80, 88, 0.1);
    border-radius: rem(4);
}

.main {
    cursor: pointer;
    position: relative;
    display: flex;
    width: rem(502);
    height: rem(56);
    padding: rem(16) rem(8);
    > .arrow {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: rem(12);
    top: rem(10); 

    &[data-expanded="true"] {
        transition: 0.3s transform;
        transform: rotate(180deg);
    }

    &[data-expanded="false"] {
        transition: 0.3s transform;
        transform: rotate(0deg);
    }
}
    >span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: fit-content;
        padding: 0 rem(8);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: #4B5058;
        &.id {
            font-weight: 400;
        }
    }

    >.photo {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: rem(44);
        }
    }
}

.aside {
    margin-top: rem(-8);
    flex-direction: column;
    padding: rem(16) rem(12);
    gap: rem(12);

    >div {
        flex-direction: column;
    }

    .color {
        display: flex;
        flex-direction: row;

        >span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4B5058;
            width: 100%;
        }
    }

    .description {
        >div {
            &:last-child {
                margin-top: rem(8);
            }

            >span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4B5058;
            }
        }

    }
}

.language-switcher {
    display: flex;
    gap: rem(6);

    >button {
        background: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        font-size: rem(14);

        &[data-active="true"] {
            color: green;
        }
    }
}
.space {
    width: 100%;
   
    margin-top: rem(50);
}