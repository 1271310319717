@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../../styles/breakpoints.scss";

.product-short-view {
  padding: rem(24);
  display: flex;
  flex-wrap: wrap;
  >div {
    &:first-child {
        width: rem(406);
        padding-right: rem(24);
    }

    &:last-child {
        width: calc(100% - rem(406));
        padding-left: rem(24);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .block {
        padding: rem(20) 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        &:first-child {
            border-top: 0;  
        }
        >.aggregator-id-container {
            display: flex;
            align-items: center;
            justify-content: space-between;


            >.text {
                display: flex;
                align-items: center;
                gap: rem(8);

                >.label {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }

                >.input-id {
                    background-color: transparent;
                    padding: 0;
                    height: rem(22);
                    border: 0;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #4B5058;
                }
            }

            >.icon {
                cursor: pointer;

                >svg {
                    margin-top: rem(2);
                }
            }
        }

        >.subtitle {
            margin-bottom: rem(16);

            >span {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
            }
        }

        >.picture {
            display: flex;
            justify-content: center;
            width: 100%;
            height: rem(220);
            > img{
               width: fit-content;
            }
        }

        >.row {
            display: flex;
            gap: rem(8);
            margin-bottom: rem(12);

            &:last-child {
                margin-bottom: 0;
            }

            >span {
                &:first-child {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    opacity: 0.4;
                }

                &:last-child {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }


        }

        >.data {
            >span {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
            }
            >.buttons {
                display: flex;

                >button {
                    background-color: transparent;
                    border: none;
                    width: fit-content;
                    height: rem(30);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    opacity: 0.6;
                    padding: 0 rem(10);
                    border: rem(1) solid transparent;

                    &:hover {
                        background: #f3f7fd;
                        border-radius: 4px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #3F8CFE;
                    }

                    &[data-active="true"] {
                        background: #dfecff;
                        border-radius: 4px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #3F8CFE;
                    }
                }
            }
            .ingredients-table {
                height: rem(380);
                overflow: auto;
                >table {
                width: 100%;
                border-collapse: separate;
                border-spacing: rem(12) 0;
                margin-left: rem(-12);
                
                thead {
                    position: sticky;
                    top: 0;
                    z-index: 22000;
                    background-color: white;
                    
                }
                th {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: #9f9f9f;
                    text-align: left;
                    padding: rem(8) 0;
                    &:nth-child(1) {
                        width:rem(40);
                    }
                    &:nth-child(2) {
                        min-width:rem(260);
                    }
                }

                td {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: #000000;
                    vertical-align: middle;
                    &:nth-child(1) {
                        font-weight: 500;
                    };                 
                }
                .position_title {
                    padding: rem(8);
                }
            }
        } 
        }



        >.row-fullwidth {
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(12);

            >span {
                &:first-child {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #9f9f9f;
                }

                &:last-child {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            .nodata {
                display: flex;
                align-items: center;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:first-child {
        padding-top: 0;
        border-top: none;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

}
}