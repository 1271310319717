.summary-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .price-wrapper {
    width: 50%;
  }

  .price-wrapper:nth-child(2),
  .price-wrapper:nth-child(4) {
    display: flex;
    justify-content: flex-end;
  }
}