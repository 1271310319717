@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "src/styles/mixins";
@import "../styles";

.stoplists-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20);

    >.buttons {
        display: flex;
        gap: rem(5);

        >button {
            background-color: transparent;
            border: none;
            padding: rem(10) rem(18);
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            opacity: 0.6;

            &:hover {
                background: #f3f7fd;
                border-radius: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3F8CFE;
            }

            &[data-active="true"] {
                background: #dfecff;
                border-radius: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3F8CFE;
            }
        }
    }
}