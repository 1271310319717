@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "src/main/styles/breakpoints";

.chip_input {
  display: flex;
  flex-direction: column;
  position: relative;

  &_wrapper {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    width: 100%;
    align-items: center;
    background: map-get($color_white, "_0");
    border: 0;
    border-radius: rem(4);
    border: rem(1) solid map-get($color_gray, "_4");

    @media screen and (max-width: $sm) {
      flex-wrap: nowrap;
    }

    &_focused {
      background-color: map-get($color_white, "_0");
      border: rem(1.5) solid map-get($color_gray, "_2");

      @media screen and (max-width: $sm) {
        flex-wrap: wrap;
      }
    }

    &_chips {
      display: flex;
      flex-wrap: wrap;

      &_icon {
        margin: rem(8) rem(14) 0 0;
      }

      &_chip {
        padding: rem(4) rem(8);
        border: rem(1.5) solid map-get($color_gray, "_1");
        border-radius: rem(40);
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        margin: 6px 4px;

        &_text {
          color: map-get($color_basic, "_0");
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: rem(138);
          cursor: pointer;
          margin-right: rem(4);
        }
      }
    }

    &_input {
      display: flex;
      justify-content: space-between;
      flex: 1 1 0;
      font-family: 'Roboto';
      font-weight: 300;
      font-size: rem(14);
      color: map-get($color_black, "_0");
      width: 100%;
      border: none;
      background-color: transparent;
      transition: 0.2s;
      outline: none;
      padding: 0;
      padding-left: rem(12);
      padding-right: rem(32);
      z-index: 2;
      height: rem(40);

      &_field {
        background-color: inherit;
        border: none;
        border-radius: 0;
        flex: 1;
        font-size: rem(14);

        &::placeholder {
          color: map-get($color_gray, "_4");
        }
      }
    }
  }

  &_item {
    padding: rem(8);
    border-bottom: rem(1) solid map-get($color_gray, "_1");
    color: map-get($color_gray, "_4");
    cursor: pointer;
    font-size: rem(14);
    transition: 0.3s all;

    &:first-child {
      border-radius: rem(4) rem(4) 0 0;
    }

    &:last-child {
      border-radius: 0 0 rem(4) rem(4);
      border: none;
    }

    &:hover {
      background: map-get($color_gray, "_0");

      &:first-child {
        border-radius: rem(4) rem(4) 0 0;
      }

      &:last-child {
        border-radius: 0 0 rem(4) rem(4);
        border: none;
      }
    }
  }

  &_select {
    margin-top: rem(4);
    left: 0;
    border: rem(1) solid map-get($color_gray, "_1");
    background: map-get($color_white, "_0");
    border-radius: rem(4);
    width: 100%;
    z-index: 10;
    box-shadow: 0 rem(16) rem(64) rgba(17, 17, 17, 0.08);
    position: absolute;
    top: 100%;

    &_close {
      display: none;
    }
  }

  &_hide {
    display: none;
  }
}

