@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "./rem.scss";
@import "./colors.scss";
@import "./breakpoints.scss";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
}

body {
  font-family: 'Roboto';
  font-weight: 300;
  line-height: 1.43;
  font-size: $html_font_size;
  color: map-get($color_basic, "_0");
  background: map-get($color_white, "_1");
  margin: 0;
}

code {
  background-color: map-get($color_gray, "_1");
  color: map-get($color_black, "_1");
  border-radius: rem(4);
  word-break: break-all;
  padding: rem(2) rem(4);
  line-height: rem(24);
}

button {
  font-family: 'Roboto';
  font-weight: 300;
  line-height: 1.43;
}

#main {
  display: flex;
  min-height: 100vh;
}

.root-container {
  z-index: 1;
  width: calc(100% - 258px);
  background: map-get($color_white, "_1");

  @media (max-width: $lg) {
    width: 100%;
  }
}

.root-container-restaurant {
  z-index: 1;
  width: calc(100% - 258px);
  background: map-get($color_yellow, "_3");

  @media (max-width: $lg) {
    width: 100%;
  }
}

.root-content {
  max-width: 93%;
  margin: auto;
  padding: rem(24) rem(40);
}

.Toastify__toast--info {
  background-color: map-get($color_basic, '_1');
}

.Toastify__toast--success {
  background-color: map-get($color_green, '_0');
}

.Toastify__toast--error {
  background-color: map-get($color_red, '_0');
}

@media (max-width: $lg) {
  #main {
    padding: 0;
  }

  .root-content {
    padding: rem(24) rem(32);
    margin: auto;
  }
}

@media (max-width: $sm) {
  .root-content {
    padding: rem(24) rem(20);
  }
}
