@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.btn-root {
  height: fit-content;
  border-radius: rem(4);
  overflow: hidden;
  display: inline-flex;
  position: relative;

  &[disabled] {
    background-color: map-get($color_gray, "_0");
    pointer-events: none;

    span {
      color: map-get($color_gray, "_5");
    }

    svg,
    path {
      fill: map-get($color_gray, "_5");
    }

    &.outline {
      background: map-get($color_gray, "_3");
      border: rem(1) solid map-get($color_gray, "_2");

      span {
        color: map-get($color_gray, "_5");
      }

      svg,
      path {
        fill: map-get($color_gray, "_5");
      }
    }
  }


  & {
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    border: rem(1) solid transparent;
    min-width: fit-content;
    border-radius: rem(4);
    box-sizing: border-box;
    letter-spacing: 0.02857em;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: 100%;
      font-size: rem(14);
      font-weight: 300;
      color: map-get($color_black, "_1");
    }

    &.default {
      background-color: map-get($color_gray, "_0");
      
      &:hover {
        background-color: map-get($color_white, "_4");
      }

      svg,
      path {
        fill: map-get($color_black, '_0')
      }

      &.outline {
        background: map-get($color_white, "_0");
        border: rem(1) solid map-get($color_gray, "_1");

        &:hover {
          background-color: map-get($color_gray, "_0");
        }

        svg,
        path {
          fill: map-get($color_black, '_0')
        }
      }
    }


    &.primary {
      background-color: map-get($color_basic, "_1");

      span {
        color: map-get($color_white, "_0");
      }

      svg,
      path {
        fill: map-get($color_white, "_0");
      }

      &:hover {
        background-color: map-get($color_secondary, "_1");
      }

      &.outline {
        background: map-get($color_white, "_0");
        border: rem(1) solid map-get($color_basic, "_1");

        span {
          color: map-get($color_black, '_0');
        }

        svg,
        path {
          fill: map-get($color_gray, '_5');
        }

        &:hover {
          border: rem(1) solid map-get($color_secondary, "_1");
          background-color: map-get($color_white, "_3");
        }
      }
    }

    &.secondary {
      background-color: map-get($color_green, "_0");

      span {
        color: map-get($color_white, "_0");
      }

      svg,
      path {
        fill: map-get($color_white, "_0");
      }

      &:hover {
        background-color: map-get($color_green, "_4");
      }

      &.outline {
        background: map-get($color_white, "_0");
        border: rem(1) solid map-get($color_green, "_0");

        span {
          color: map-get($color_green, "_0");
        }

        svg,
        path {
          fill: map-get($color_green, "_0");
        }

        &:hover {
          background-color: map-get($color_green, "_2");
          border: rem(1) solid map-get($color_green, "_3");
        }
      }
    }

    &.danger {
      background-color: map-get($color_red, "_0");

      span {
        color: map-get($color_white, "_0");
      }

      svg,
      path {
        fill: map-get($color_white, "_0");
      }

      &:hover {
        background-color: map-get($color_red, '_4');
      }

      &.outline {
        background: map-get($color_white, "_0");
        border: rem(1) solid map-get($color_red, "_1");

        span {
          color: map-get($color_red, "_0");
        }

        svg,
        path {
          fill: map-get($color_red, "_0");
        }

        &:hover {
          background-color: map-get($color_red, "_3");
          border: rem(1) solid map-get($color_red, "_1");
        }
      }
    }

    .opacity {
      opacity: 0.2;
    }

    .loading {
      position: absolute;
      display: flex;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      height: 0;

      &.right {
        padding-left: rem(8);
      }

      &.left {
        padding-right: rem(8);
      }
    }

    &.add {
      padding: rem(8) rem(8);

      svg {
        width: rem(16);
        height: rem(16);
      }
    }

    &.small {
      padding: rem(6) rem(10);

      svg {
        width: rem(18);
        height: rem(18);
      }
    }

    &.medium {
      padding: rem(8) rem(16);

      svg {
        width: rem(16);
        height: rem(16);
      }
    }

    &.large {
      padding: rem(10) rem(22);

      svg {
        width: rem(26);
        height: rem(26);
      }
    }
  }
}
