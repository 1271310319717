@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

@mixin customScroll() {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: .3s ease;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    color: rgba(0, 0, 0, 0.1);

    &:hover {
      color: rgba(0, 0, 0, 0.2);
      transition: color .6s ease;
    }
  }
}

@mixin tableHeaderText() {
@include typography();
font-size: rem(14);
color: map-get($color_black, '_1');
}



@mixin typography() {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
line-height: 24px;
}

.container {
  display: flex;
  flex-direction: column;
}

.restaurant-menu_header {
  padding: rem(20);
  border-bottom: solid rem(1) map-get($color_gray, '_1');
  >p {
  @include typography();
  color: black;
  }
}

.restaurant-menu_content-container {
  display: flex;
}

.restaurant-menu_footer {
  display: flex;
  justify-content: flex-end;
  padding: rem(20) rem(30);
  border-top: solid rem(1) map-get($color_gray, '_1');
  >div {
    display: flex;
    gap: rem(12);
  }
}

.restaurant-menu_table {
  padding: 0 rem(24);
  >p {
    @include typography();
    font-size: 16px;
  }
    &:last-child {
    border-left: solid rem(1) map-get($color_gray, '_1');
  }
}

.menu-table_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(560);
  height: calc(100vh - rem(210));
}

@include customScroll();
.menu-table_wrapper {
  width: rem(560);
  height: calc(100vh - rem(248));
  overflow-y: auto;
  &[data-loading=true] {
    pointer-events: none;
    opacity: 0.6;
  }

  >table{
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 rem(16);
  }
}

.icon-wrapper {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

.menu-table_head {
  position: sticky;
  top: 0;

  >th {
    @include tableHeaderText();
    text-align: left;
    background-color: #ffffff;
    opacity: 1;

    &:nth-child(1){
      width: rem(78);
    }
    &:nth-child(2){
      width: rem(64);
    }
    &:nth-child(3){
      width: rem(210);
    }
    &:nth-child(4){
      width: rem(50);
      text-align: right;
    }
    &:nth-child(5){
      width: rem(130);
      text-align: right;
    }
  }
}

.menu-table_row {
  height: rem(56);
  &:hover {
      background-color: map-get($color_gray, '_0');
  }
  cursor: pointer;
  >td {
    @include tableHeaderText();
    border: solid thin map-get($color_gray, '_0');
    vertical-align: middle;
    
    &:nth-child(1){
      padding-left: rem(2);
      border-radius: rem(4) 0 0 rem(4);
      border-right: none;
    }
    &:nth-child(2){
      border-left: none;
      border-right: none;
    }
    &:nth-child(3){
      border-left: none;
      border-right: none;
    }
    &:nth-child(4){
      border-left: none;
      border-right: none;
      text-align: right;
    }
    &:nth-child(5){
      border-left: none;
      text-align: right;
      vertical-align: middle;
      padding-right: rem(16);
      border-radius: 0 rem(4) rem(4) 0;
    }
  }
}

.product-image {
  width: rem(54);
}