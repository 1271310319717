@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

  .wrapper {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    width: 100%;

    .button-submit {
      outline: none;
      border: none;
      background-color: map-get($color_orange, "_0");
      height: rem(56);
      border-radius: rem(8);
      width: 100%;
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_white, "_0");

      &:active {
        background-color: map-get($color_orange, "_0");
      }
    }

    .button-print {
      outline: none;
      border: none;
      background-color: map-get($color_green, "_5");
      height: rem(56);
      border-radius: rem(8);
      width: 100%;
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_white, "_0");

      &:active {
        background-color: map-get($color_orange, "_0");
      }
    }

    .button-cancel {
      outline: none;
      border: none;
      background-color: map-get($color_gray, "_3");
      height: rem(56);
      border-radius: rem(8);
      width: 100%;
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_white, "_0");

      &:active {
        background-color: map-get($color_gray, "_3");
      }
    }
}
