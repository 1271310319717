@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.combo-view-container {
    padding: rem(24);
    display: flex;

    >div {
        &:first-child {
            width: rem(406);
            padding-right: rem(24);
        }

        &:last-child {
            width: calc(100% - rem(406));
            padding-left: rem(24);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }

        .block {
            padding: rem(20) 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            >.subtitle {
                margin-bottom: rem(16);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            >.position-container {
                display: flex;
                align-items: center;
                justify-content: space-between;


                >.text {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;

                    >.label {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: #000000;
                    }

                    >.input-id {
                        max-width: rem(60);
                        border: rem(1) solid rgba(142, 142, 142, 0.1);
                        border-radius: rem(4);
                        padding: rem(16);
                        text-align: right;
                        height: rem(22);
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #4B5058;

                        &[data-invalid=true] {
                            color: map-get($color_red, '_4')
                        }
                    }
                }

               
            }

            >.picture {
                display: flex;
                justify-content: center;
                width: 100%;
                height: rem(220);

                >img {
                    width: fit-content;
                }
            }

            >.row {
                display: flex;
                gap: rem(8);
                margin-bottom: rem(12);

                &:last-child {
                    margin-bottom: 0;
                }

                >span {
                    &:first-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        opacity: 0.4;
                    }

                    &:last-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }
                }


            }

            >.data {
                margin-top: rem(-8);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            >.combo-details {
                min-height: rem(50);

                >.toolbar {
                    >.buttons {
                        align-items: center;
                        display: flex;
                        gap: rem(5);

                        >button {
                            background-color: transparent;
                            border: none;
                            max-width: rem(70);
                            max-height: rem(40);
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                            opacity: 0.6;

                            &:hover {
                                background: #f3f7fd;
                                border-radius: 4px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: #3F8CFE;
                            }

                            &[data-active="true"] {
                                background: #dfecff;
                                border-radius: 4px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: #3F8CFE;
                            }
                        }
                    }
                }


                width: 100%;

                .position-title {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #000000;
                    vertical-align: middle;
                }

                .content-table {
                    max-height: rem(500);
                    overflow-y: auto;

                    >table {
                        table-layout: fixed;
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: rem(8) 0;
                        margin-left: rem(-8);
                        min-width: rem(492);

                        th {
                            position: sticky;
                            top: 0;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 16px;
                            color: #9f9f9f;
                            text-align: left;
                            background-color: white;
                            z-index: 100;
                            padding: rem(10) 0;

                            &:nth-child(1) {
                                width: rem(190);
                            }

                            ;

                            &:nth-child(2) {
                                text-align: center;
                                max-width: rem(120);
                            }

                            &:nth-child(3) {
                                text-align: center;
                                max-width: rem(120);
                            }

                            &:nth-child(4) {
                                text-align: center;
                                max-width: rem(120);
                            }

                            &:nth-child(5) {
                                text-align: center;
                                max-width: rem(120);
                            }
                        }

                        td {
                            //display: inline-flex;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            color: #000000;
                            vertical-align: middle;
                            background-color: white;

                            >div {
                                width: fit-content;
                                margin: 0 auto;
                            }
                        }
                    }
                }


            }

            &:first-child {
                padding-top: 0;
                border-top: none;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    .skelet-table {
        td {
            border: none;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;

        >span {
            &:last-child {
                text-align: right;
            }

        }

        >div {
            gap: rem(16);
        }
    }

    .error {
        margin-top: rem(4);
        font-size: rem(12);
        color: map-get($color_red, '_4');
    }
}