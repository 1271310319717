@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

@mixin scrollbars() {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: .3s ease;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {        
    box-shadow: inset 0 0 0 10px;
    color: rgba(0, 0, 0, 0.1);
    &:hover{
      color: rgba(0, 0, 0, 0.2);
      transition: color .6s ease;
    }
  }
}
.autocomplete {
  @include scrollbars();
  >.suggestions {
    margin-top: rem(16);
    display: block;
    width: rem(504);
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    position: absolute;
    z-index: 3;
    background: map-get($color_white, "_0");
    border-radius: rem(4);
    box-shadow: 0px 4px 8px 6px rgb(0 0 0 / 15%);

    .suggestion {
      cursor: pointer;
      height: rem(44);
      display: flex;
      padding: 0 rem(12) 0 rem(12);
      align-items: center;
      background-color: #fff;
      &-id {
        text-align: right;
        width: rem(40);
        font-weight: bold;
        margin-right: rem(10);
      }
      &-label-blue {
        color: map-get($color_primary, _0);
        display: flex;
        align-items: center;
        gap: rem(6);
      }

      &:last-child {
        border-radius: 0 0 rem(4) rem(4);
      }

      &:first-child {
        border-radius: rem(4) rem(4) 0 0;
      }

      &:hover {
        background-color: map-get($color_gray, _0);
      }
    }
  }
}
