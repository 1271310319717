@import "../../../styles/colors.scss";
@import "../../../main/styles/rem.scss";

.sidebar-row-link-restaurant {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.1s;
  padding: rem(10) 0;
  padding-right: rem(12);
  padding-left: rem(12);
  justify-content: space-between;
  position: relative;

  &[data-active="true"] > div > span {
    color: map-get($color_brown, "_0");
  }

  .row {
    display: flex;
    align-items: center;
  }

  .restaurants {
    width: 48px;
    height: 48px;
  }

  .closed {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
