@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../header/styles.scss";

.card-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(16);

    .green {
      background-color: map-get($color_green, "_5");
    }

    .gray {
      background-color: map-get($color_gray, "_3");
    }
  
    .card-status {
      width: rem(40);
      border-radius: rem(8);
      justify-content: center;
      display: flex;
      align-items: center;
      margin-right: rem(8);
      cursor: pointer;

      .time-string {
        font-size: rem(14);
      }
      
      .disabled {
        background-color: map-get($color_green, "_5");
        width: rem(40);
        border-radius: rem(8);
        justify-content: center;
        display: flex;
        align-items: center;
        margin-right: rem(8);
        cursor: pointer;
      }
  
      .info-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .circle-confirm {
          height: rem(28);
          width: rem(28);
          background-color: map-get($color_white, '_5');
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;
        }
    
        .status-text {
          font-family: 'Flame';
          font-size: rem(10);
          color: map-get($color_white, '_0');
        }
      }
    }

    .color-delay{
      background-color: map-get($color_green, "_5");
    }
  
    .main-container {
      background-color: map-get($color_white, '_0');
      min-width: rem(316);
      border-radius: rem(8);
      width: 100%;
      padding: rem(12);
      text-decoration: none;
      cursor: pointer;
  
      .title-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: rem(0.25) solid map-get($color_white, "_6");
        margin-bottom: rem(8);
  
        .title-text {
          font-family: 'Flame';
          font-size: rem(20);
          color: map-get($color_brown, '_0');
          margin-bottom: rem(8);
        }
  
        .sum-text-wrapper {
          display: flex;
          flex-direction: row;
        }
  
        .sum-text {
          font-family: 'Flame';
          font-size: rem(16);
          color: map-get($color_gray, '_9');
        }
      }
      
      .additional-info-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .info-title {
          font-family: 'Rotonda';
          font-size: rem(20);
          color: map-get($color_gray, '_9');
          padding-right: rem(10);
          width: 100%;
  
          .info-text {
            font-family: 'Rotonda';
            font-size: rem(20);
            color: map-get($color_brown, '_0');
          }
        }
      }
    }
  }

  .delete-order{
    display: none;
  }

