@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "src/styles/mixins";
@import "../styles";

.toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20);

    >.buttons {
        display: flex;
        gap: rem(5);

        >button {
            background-color: transparent;
            border: none;
            width: rem(114);
            height: rem(44);
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            opacity: 0.6;

            &:hover {
                background: #f3f7fd;
                border-radius: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3F8CFE;
            }

            &[data-active="true"] {
                background: #dfecff;
                border-radius: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #3F8CFE;
            }
        }
    }

    >.aggregator-id-container {
        display: flex;
        align-items: center;
        gap: rem(12);

        >.text {
            display: flex;
            align-items: center;
            gap: rem(8);
            >.label {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #4B5058;
                opacity: 0.4;
            }

            >.input-id {
                background-color: map-get($color_gray, '_0');
                text-align: right; 
                height: rem(22);
                padding: rem(16);
                border: 0;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #4B5058;
            }
        }
        >.icon {
            cursor: pointer;
            >svg {
                margin-top: rem(2);
            }
        }
    }
}
  