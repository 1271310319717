.tabs-buttons {
    display: flex;
    gap: rem(5);

    >button {
        background-color: transparent;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        height: rem(50);
        color: #000000;
        opacity: 0.6;
        height: rem(50);
        

        &:hover {
            background: #f3f7fd;
            border-radius: 4px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #3F8CFE;
        }

        &[data-active="true"] {
            background: #dfecff;
            border-radius: 4px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #3F8CFE;
        }
    }
}