.splash-page {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(18);
    margin-top: 10%;
    >.spinner {
        margin-top: 5%;
    }
}