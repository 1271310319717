@import "../../../styles/colors.scss";
@import "../../../main/styles/rem.scss";
@import "../../../styles/breakpoints.scss";

@mixin simple-circle {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  padding: rem(12);
  border-radius: rem(50);
  transition: 0.2s;
  cursor: pointer;
}

@mixin circle-hover {
  background-color: map-get($color_gray, "_2");
}

@mixin ru {
  background-image: url('../../../assets/images/russia.png');
}

@mixin en {
  background-image: url('../../../assets/images/uk.png');
}

@mixin kaz {
  background-image: url('../../../assets/images/kaz.jpg');
}

#--header-root {
  padding: rem(8) rem(20);
  max-height: rem(64);
  display: flex;
  align-items: center;
  background-color: map-get($color_white, "_0");
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0 rem(2) rem(3) map-get($color_gray, "_6");

  @media (max-width: $lg) {
    padding: rem(8) rem(20);
  }

  @media (max-width: $sm) {
    padding: rem(8) rem(4);
  }

  .burger {
    button {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }

      @media (min-width: $lg) {
        display: none;
      }
    }
  }

  .content-restaurant {
    justify-content: flex-end!important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    .search {
      display: flex;
      position: relative;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .language {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }

      .ru {
        @include ru;
      }

      .en {
        @include en;
      }

      .kaz {
        @include kaz;
      }

      .language-image {
        background-size: 160%;
        background-position: center;
        border-radius: rem(20);
        width: rem(22);
        height: rem(22);
      }
    }

    .bell {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }

      .bell-image {
        width: rem(22);
        height: rem(22);
        background-image: url('../../../assets/images/bell.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: transparent;

        .bell-circle {
          width: rem(16);
          height: rem(16);
          background-color: map-get($color_orange, "_0");
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: center;
          align-items: center;
          position: absolute;
          margin-left: rem(10);
          margin-top: rem(-5);

          .circle-text {
            font-size: rem(8);
            color: map-get($color_white, "_0");
          }
        }
      }
    }

    .vl {
      border: 1px solid map-get($color_yellow, "_3");
      height: 36px;
      border-radius: 4px;
      margin-inline: 10px;
    }

    .logout {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }
    }
  }
}
