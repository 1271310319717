@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.confirm-root {
  padding: rem(24);
  min-width: rem(440);
  .confirm-text {
    font-size: rem(16);
    >b {
      font-size: rem(16);
      word-wrap: break-word
    }
  }
  > h2 {
    font-weight: 500;
    font-size: rem(24);
    margin: 0;
    margin-bottom: rem(16);
    border-bottom: rem(1) solid map-get($color_gray, "_1");
    margin-left: rem(-24);
    margin-right: rem(-24);
    padding-left: rem(24);
    padding-right: rem(24);
    margin-top: rem(-24);
    padding-top: rem(16);
    padding-bottom: rem(16);

    & ~ h2 {
      margin: rem(16) 0;
    }
  }

  > .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: rem(24);
    width: 100%;
    gap: rem(8);
    div,
    button {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .single {
    margin-top: rem(24);
    width: 100%;

    div,
    button {
      width: 100%;
    }
  }
}

