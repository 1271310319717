@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.textarea-container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  background: map-get($color_white, "_0");
  width: 100%;
  border: 0;
  border-radius: rem(4);

  textarea {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: rem(14);
    resize: none;
    color: map-get($color_black, "_0");
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
    margin-top: rem(8);
    padding-left: rem(12);
    padding-right: rem(32);
    z-index: 2;
    min-height: rem(52);
    max-height: rem(200);

    &:focus {
      outline: none;
    }

    &.autosize {
      min-height: auto;
      max-height: inherit;
      padding-bottom: rem(8);
    }
  }

  label {
    position: absolute;
    z-index: 1;
    top: rem(12);
    left: 0;
    font-weight: 300;
    font-size: rem(13);
    left: rem(12);
    transition: 0.2s;
    border-radius: rem(4);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    top: rem(-10);
    font-size: rem(12);
    background: map-get($color_white, "_0");
    padding: 0 rem(4);
    left: rem(8);
  }

  .is-valid {
    display: flex;
    position: absolute;
    right: rem(12);
    top: rem(8);
  }

  .is-danger {
    display: flex;
    position: absolute;
    right: rem(10);
    top: rem(8);
  }

  &.default {
    border: rem(1) solid map-get($color_gray, "_1");

    > label {
      color: map-get($color_gray, "_4");
    }
  }

  &.primary {
    border: rem(1) solid map-get($color_black, "_1");

    label {
      color: map-get($color_black, "_1");
    }
  }

  &.secondary {
    border: rem(1) solid map-get($color_green, "_0");

    label {
      color: map-get($color_green, "_0");
    }
  }

  &.danger {
    border: rem(1) solid map-get($color_red, "_0");

    label  {
      color: map-get($color_red, "_0");
    }
  }

  &[disabled] {
    border: rem(1) solid map-get($color_gray, "_4");

    > label  {
      color: map-get($color_gray, "_4");
    }

    textarea {
      pointer-events: none;
      color: map-get($color_gray, "_4");
    }
  }
}