@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.advantages_container {
    display: flex;
    flex-wrap: wrap;

    .advantage_container {
        margin-right: rem(4);
        margin-bottom: rem(4);

        &:last-child {
            margin-right: 0;
        }
    }
}

.working-hours {

    >table {
        width: 100%;

        th {
            padding: 0 0 rem(20) 0;

            &:first-child {
                text-align: left;
            }

            text-align: center;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        td {
            padding: rem(8) 0 rem(8) 0;
            text-align: center;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.8);

            &:nth-child(1) {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.4);
                text-align: left;
            }

            &:nth-child(2) {
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:nth-child(3) {
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.restaurant-view-container {
    padding: rem(24);
    display: flex;

    >div {
        &:first-child {
            width: calc(100% - rem(406));

            padding-right: rem(24);
        }

        &:last-child {
            width: rem(406);
            padding-left: rem(24);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }

        .block {
            padding: rem(20) 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            >.subtitle {
                margin-bottom: rem(16);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }
            >.row {
                &-controlled {
                display: flex;
                margin-bottom: rem(12);
                >div:first-child{
                    min-width: rem(160);
                    display: flex;
                    align-items: center;
                     >span {
                        align-items: center;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        opacity: 0.4;
                    }
                }
          
                }
                display: flex;
                gap: rem(8);
                margin-bottom: rem(12);
                &:last-child {
                    margin-bottom: 0;
                }

                >span {
                    &:first-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        opacity: 0.4;
                    }

                    &:last-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }
                }
                >.icon {
                    display: flex;
                    align-items: center;
                }
            }

            >.menu {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: rem(12);
                width: 100%;
                height: rem(80);
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                padding: rem(12) rem(8);
                >.picture {
                    width: rem(56);
                    height: rem(64);
                    margin-right: rem(16);
                    display: flex;
                    align-items: center;
                    //background-color: #F8F8F8;
                    >.menu-icon {
                        line-height: normal;
                    }
                }

                >h2 {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
                >.icon {
                    display: flex;
                    margin-left: auto;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    background-color: #F8F8F8;
                }
            }

            >.advantages {
                display: flex;
                flex-wrap: wrap;
                gap: rem(8);
            }

            >.working-hours {

                > table {
                    width: 100%;

                    th {
                        padding: 0 0 rem(20) 0;

                        &:first-child {
                            text-align: left;
                        }

                        text-align: center;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    td {
                        padding: rem(8) 0 rem(8) 0;
                        text-align: center;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.8);

                        &:nth-child(1) {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(0, 0, 0, 0.4);
                            text-align: left;
                        }

                        &:nth-child(2) {
                            border-right: 1px solid rgba(0, 0, 0, 0.1);
                        }

                        &:nth-child(3) {
                            border-right: 1px solid rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }

            &:first-child {
                padding-top: 0;
                border-top: none;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

.skelet-table {
    width: 100%;

    th {
        padding: 0 0 rem(20) 0;

        &:not(:first-child) {
            > div {
              margin: 0 auto;
            }
          }

        &:first-child {
            text-align: left;
        }

        text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    td {
        padding: rem(8) 0 rem(8) 0;
        // text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.8);

        &:not(:first-child) {
          > div {
            margin: 0 auto;
          }
        }

        &:nth-child(1) {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.4);
            text-align: left;
        }

        &:nth-child(2) {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        &:nth-child(3) {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}