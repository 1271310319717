@import "../../../styles/colors.scss";
@import "../../../main/styles/rem.scss";

.sidebar-row-link-restaurant {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.1s;
  padding: rem(10) 0;
  padding-right: rem(12);
  padding-left: rem(12);
  justify-content: space-between;
  position: relative;

  &[data-active="true"] > div > span {
    color: map-get($color_brown, "_0");
  }

  span {
    color: map-get($color_brown, "_1");
    font-size: rem(20);
    padding: 0 rem(8);
    font-weight: 400;
    font-family: "Flame";
  
    &:hover {
      color: map-get($color_brown, "_0");
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .analytics-box {
    position: absolute;
    right: rem(28);
    background-color: map-get($color_black, "_2");
    height: rem(26);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(4);

    span {
      padding: rem(8);
    }
  }
}
