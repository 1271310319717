@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.time-picker {
  cursor: pointer;
  width: 48%;
  height: rem(48);
  
   input {
      cursor: pointer 
   }
}