@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "src/main/styles/breakpoints";

.color-selector_container {
  display: flex;

  .colors {
    display: flex;
    gap: rem(12);
          
    .color {
      display: flex;
      border-radius: rem(20);
    }
  
    >button {
      width: rem(26);
      height: rem(26);
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      border-radius: rem(50);
      transition: 0.2s;
      background-color: map-get($color_white, '_0');
      &:hover {
        opacity: 0.7 !important;
      }
      &[data-active="true"] {
        opacity: 0.8 !important;

      }
      .color-selected {
        display: flex;
        justify-content: center;
        width: rem(10);
        height: rem(10);
        border-radius: rem(8);
        background-color: map-get($color_white, '_0');
        transition: 0.2s;
        opacity: .8;
      }
    }
  }
}