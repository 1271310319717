@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.details-container {
  display: flex;
  flex-direction: column;

  .detail-title {
    font-family: "Flame";
    font-size: rem(18);
    padding-top: rem(12);
    padding-bottom: rem(4)
  }

  .add {
    color: map-get($color_green, "_5");
  }

  .remove {
    color: map-get($color_red, "_4");
  }

  .top-4 {
    padding-top: rem(4);
  }
                
  .detail-wrapper {
    display: flex;
    justify-content: space-between;

      .detail {
      font-family: "Rotonda";
      font-style: normal;
      font-weight: normal;
      font-size: rem(18);
      color: #9F816F;
      }

      .detail-side {
        font-family: "Rotonda";
        font-style: normal;
        font-weight: normal;
        font-size: rem(18);
        color: map-get($color_brown, "_0");
      }
  }
}