@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";


.receipt-container {
    max-height: calc(100% - 157px);
    margin-bottom: 2.5rem;
    overflow-y: scroll;
    padding-bottom: 4.375rem;
    border: 2px solid map-get($color_black, "_3");
    width: rem(303);
}

.receipt-container::-webkit-scrollbar {
  width: 0;
}
    .receipt-wrapper {
      padding-inline: rem(16);
      padding-top: rem(20);
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: rem(303);

      svg {
        margin: auto;
      }

      .receipt-add-info {
        padding-top: rem(20);
        padding-bottom: rem(20);
        margin: auto;
        text-align: center;

        p {
          font-family: 'Roboto';
          size: rem(12);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 400;
        }
      }

      .receipt-order-number {
        border: 2px solid map-get($color_black, "_3");
        width: 100%;
        padding-top: rem(8);

        .receipt-order-code {
          text-align: center;
          font-family: 'Roboto';
          font-size: rem(22);
          line-height: rem(22);
          color: map-get($color_black, "_3");
          font-weight: 500;
        }
        
        .receipt-status {
          text-align: center;
          font-family: 'Roboto';
          font-size: rem(35);
          line-height: rem(35);
          color: map-get($color_black, "_3");
          font-weight: 500;
        }

        p {
          text-align: center;
          font-family: 'Roboto';
          font-size: rem(12);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 400;
        }

        .receipt-number {
          text-align: center;
          font-family: 'Roboto';
          font-size: rem(54);
          line-height: rem(70);
          color: map-get($color_black, "_3");
          font-weight: 600;
        }
      }

      .receipt-date-time {
        display: flex;
        justify-content: space-between;
        padding-top: rem(20);
        padding-bottom: rem(20);

        .receipt-date-time-title {
          font-family: 'Roboto';
          font-size: rem(12);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 400;
        }

        .receipt-time {
          text-align: end;
        }

        .receipt-data {
          font-family: 'Roboto';
          font-size: rem(16);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 400;
        }
      }

      .receipt-structure-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid map-get($color_black, "_3");

        .receipt-structure {
          display: flex;
          justify-content: space-between;

          .receipt-count-pd {
            padding-right: rem(20);
          }
        }
        
        p {
          font-family: 'Roboto';
          font-size: rem(12);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 500;
        }
      }

      .receipt-ingredients-wrapper {
        display: flex;
        justify-content: space-between;
        padding-top: rem(12);

        p {
          font-family: 'Roboto';
          font-size: rem(12);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 400;
        }

        .receipt-ingredients {
          display: flex;
          justify-content: space-between;

          p {
            font-family: 'Roboto';
            font-size: rem(12);
            line-height: rem(20);
            color: map-get($color_black, "_3");
            font-weight: 400;
          }
        }
      }

      .receipt-cond-wrapper {
        display: flex;
        justify-content: space-between;
        padding-top: rem(2);

        p {
          font-family: 'Roboto';
          font-size: rem(12);
          line-height: rem(20);
          color: map-get($color_black, "_3");
          font-weight: 400;
        }

        .receipt-cond {
          display: flex;
          justify-content: space-between;

          p {
            font-family: 'Roboto';
            font-size: rem(12);
            line-height: rem(20);
            color: map-get($color_black, "_3");
            font-weight: 400;
          }
        }
      }

      .receipt-footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid map-get($color_black, "_3");
        padding-top: rem(12);
        margin-top: rem(12);

        .receipt-footer-right {
          text-align: end;
        }

        div p {
          font-family: 'Roboto';
            font-size: rem(12);
            line-height: rem(20);
            color: map-get($color_black, "_3");
            font-weight: 400;
            padding-top: rem(4);
        }
      }

      .receipt-result-price {
        display: flex;
        justify-content: space-between;
        padding-top: rem(20);
        padding-bottom: rem(20);

        p {
          font-family: 'Roboto';
            font-size: rem(24);
            line-height: rem(32);
            color: map-get($color_black, "_3");
            font-weight: 500;
        }
      }
    }
  


  .receipt-count-pr {
    padding-right: rem(20);
  }