@import "../../styles/rem.scss";

.secondary-tabs {
  display: flex;
  gap: rem(5);

  >button {
      background-color: transparent;
      border: none;
      padding: rem(8) rem(14);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      opacity: 0.6;

      &:hover {
          background: #f3f7fd;
          border-radius: rem(4);
          font-style: normal;
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(24);
          color: #3F8CFE;
      }

      &[data-active="true"] {
          background: #dfecff;
          border-radius: rem(4);
          font-style: normal;
          font-weight: 400;
      font-size: rem(16);
          line-height: rem(24);
          color: #3F8CFE;
      }
  }
}