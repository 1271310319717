@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

@mixin scrollbars() {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: .3s ease;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {        
    box-shadow: inset 0 0 0 10px;
    color: rgba(0, 0, 0, 0.1);
    &:hover{
      color: rgba(0, 0, 0, 0.2);
      transition: color .6s ease;
    }
  }
}

.single-select {
  width: 100%;

  &_search {
      width: 100%;

      &_selected {
        width: inherit;
        height: max-content;
        display: flex;
        flex-direction: column;
       

        &_selected {
          margin-top: rem(10);
          width: inherit;
          display: flex;
          height: rem(36);
          padding: rem(10) 0 rem(10) 0;
          align-items: center;

        &_delete {
          display: flex;
          cursor: pointer;
          align-items: center;
          margin-right: rem(11);
        }
          .control_container_select {
            display: flex;
            justify-content: space-between;
            background-color: map-get($color_gray, "_0");
            border-radius: rem(4);
            width: rem(81);
            padding: 0 rem(14)  0 rem(14);
            height: inherit;
            flex-direction: row;
            margin-left: auto;
            align-items: center;
        
            .increase_container {
              .increase_button {
                display: flex;
                justify-content: center; 
                align-items: center;
                background: transparent;
                border: 0;
                padding: 0;
                color: map-get($color_black, "_0");
              }
        
              .decrease_label {
                font-size: rem(18);
                align-items: center;
              }
            }
            .number_container {
                display: flex;
                justify-content: center; 
                align-items: center;
                background: transparent;
                border: 0;
                padding: 0;
                color: map-get($color_black, "_0");

                .decrease_label {
                    align-items: center;
                    font-size: rem(20);
                }
            }

            .decrease_container {
              .decrease_button {
                display: flex;
                justify-content: center; 
                align-items: center;
                background: transparent;
                border: 0;
                padding: 0;
                color: map-get($color_black, "_0");
                font-size: rem(18);
        
                .decrease_label {
                  font-size: rem(18);
                  align-items: center;
                }
              }
            }
          }
      }
      }
      @include scrollbars();
      .suggestions {
        margin-top: rem(16);
        display: block;
        width: rem(504);
        max-height: rem(200);
        overflow-y: auto;
        position: absolute;
        z-index: 3;
        background: map-get($color_white, "_0");
        border-radius: rem(4);
        box-shadow: 0px 4px 8px 6px rgb(0 0 0 / 15%);
    
        >div {
            cursor: pointer;
            height: rem(44);
            display: flex;
            padding: 0 rem(12) 0 rem(12);
            align-items: center;
            background-color: #fff;
            &-label-blue {
              color: map-get($color_primary, _0);
              display: flex;
              align-items: center;
              gap: rem(6);
            }
            &:last-child {
              border-radius: 0 0 rem(4) rem(4);
            }
            
            &:first-child {
              border-radius: rem(4) rem(4) 0 0;
            }
            &:hover{
              background-color: map-get($color_gray, _0);
            }        
        }
    }
  }
}