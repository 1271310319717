@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.product-view-container {
    padding: rem(24);
    display: flex;

    >div {
        &:first-child {
            width: rem(406);
            padding-right: rem(24);
        }

        &:last-child {
            width: calc(100% - rem(406));
            padding-left: rem(24);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }

        .block {
            padding: rem(20) 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            &:first-child {
                border-top: 0;  
            }
               >.aggregator-id-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                >.text {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content:space-between;

                    >.label {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: #000000;
                    }

                    >.input-id {
                        max-width: rem(60);
                        border: rem(1) solid rgba(142, 142, 142, 0.1);
                        border-radius: rem(4);
                        padding: rem(16);
                        text-align: right;
                        height: rem(22);
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #4B5058;
                        &[data-invalid = true] {
                            color: map-get($color_red, '_4')
                        }
                    }
                }

                >.icon {
                    cursor: pointer;

                    >svg {
                        margin-top: rem(2);
                    }
                }
            }

            >.subtitle {
                margin-bottom: rem(16);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            >.picture {
                display: flex;
                justify-content: center;
                width: 100%;
                height: rem(220);
                > img{
                   width: fit-content;
                }
            }

            >.row {
                display: flex;
                gap: rem(8);
                margin-bottom: rem(12);

                &:last-child {
                    margin-bottom: 0;
                }

                >span {
                    &:first-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        opacity: 0.4;
                    }

                    &:last-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }
                }


            }

            >.data {
                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
                >.buttons {
                    display: flex;
                    margin-bottom: rem(8);
                    >button {
                         background-color: transparent;
                            border: none;
                          padding: rem(8) rem(10);
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                            opacity: 0.6;
    
                        &:hover {
                            background: #f3f7fd;
                            border-radius: 4px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #3F8CFE;
                        }
    
                        &[data-active="true"] {
                            background: #dfecff;
                            border-radius: 4px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #3F8CFE;
                        }
                    }
                }
                .ingredients-table {
                    height: rem(380);
                    overflow: auto;
                    >table {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: rem(12) 0;
                    margin-left: rem(-12);
                    
                    thead {
                        position: sticky;
                        top: 0;
                        z-index: 22000;
                        background-color: white;
                        
                    }
                    th {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #9f9f9f;
                        text-align: left;
                        padding: rem(8) 0;
                        &:nth-child(1) {
                            width:rem(40);
                        }
                        &:nth-child(2) {
                            min-width:rem(260);
                        }
                    }

                    td {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        color: #000000;
                        vertical-align: middle;
                        &:nth-child(1) {
                            font-weight: 500;
                        };                 
                    }
                    .position_title {
                        padding: rem(8);
                    }
                }
            } 
            }



            >.row-fullwidth {
                display: flex;
                justify-content: space-between;
                margin-bottom: rem(12);

                >span {
                    &:first-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #9f9f9f;
                    }

                    &:last-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }
                }

                .nodata {
                    display: flex;
                    align-items: center;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &:first-child {
            padding-top: 0;
            border-top: none;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }

    }
    .error {
        color: red;
    }
    .disabled {
        pointer-events: none;
    }

    .row-space-between {
        display: flex;
        justify-content: space-between;
    }
}

.numbers {
    width: 100%;
    display: block;
    text-align: right;
}