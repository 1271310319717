@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

body .MuiPaper-elevation24 {
  box-shadow: none;
}

.modal-root {
  height: fit-content;
  overflow-x: hidden;
  padding: rem(20);

  .text-field-box {
    display: flex;
    align-items: center;
    transition: 0.3s;
    border-radius: rem(4);

    input {
      padding: rem(10);
      font-size: rem(16);
      outline: none;
      border: solid 1px map-get($color_gray, "_8");
      color: map-get($color_brown, "_0");
      border-radius: rem(8);
      transition: 0.3s;
      margin-left: rem(-14);
      padding-left: rem(32);
      background: transparent;
      width: 100%;
      height: rem(48);

      &::placeholder {
        color: map-get($color_gray, "_8");
      }
    }
  }

  > .header-restaurant {
    margin-bottom: rem(0);
    border-bottom: rem(1) solid map-get($color_gray, "_8");
    padding-bottom: rem(16);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background: map-get($color_white, '_0');

    .restaurant-model-header {
      font-size: rem(24);
      font-family: "Flame";
      color: map-get($color_brown, "_0");
    }

    h2 {
      font-weight: 500;
      font-size: rem(24);
      margin: 0;
    }

    button {
      padding: 0;
      height: fit-content;
      margin-left: auto;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    background: map-get($color_black, "_4");
  }
}
