@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.create-definition {
  .container_fullwidth {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-left: rem(28);
    padding-right: rem(28);
    padding-bottom: rem(20);
    padding-top: rem(20);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
    .definitions-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .langs {
        height: 100%;
        margin-bottom: rem(20);
        display: flex;
        flex-direction: row;
        .lang {
          margin-right: rem(8);
          &:last-child {
            margin-right: 0;
          }
        }  
      }
    }
    &:last-child{
      border-bottom: 0;
      margin-bottom: rem(16)
    }
    &_half {
      margin-top: rem(16);
      width: rem(244);
  
      &_first {
        margin-top: 0;
        width: rem(244);
      }
    }
    &_third {
      margin-top: rem(16);
      width: rem(157);
  
      &_first {
        margin-top: 0;
        width: rem(157);
      }
    }
    &_one {
      margin-top: rem(16);
      width: 100%;
  
      &_first {
        margin-top: 0;
        width: 100%;
      }
    }
  }
  .footer {
    height: rem(80);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 rem(28) 0 rem(28);
    align-items: center;
    background-color: map-get($color_white, '_0');
    width: 100%;
    bottom: 0;
    z-index: 2;
  }
}
