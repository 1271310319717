@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.uploading-files {
  width: 100%;
  height: rem(180);
  border: rem(1) solid map-get($color_gray, "_0");
  border-radius: rem(4);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);

  .file-container {
    width: 85%;
    height: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_preview {
      border: rem(1) solid map-get($color_gray, "_0");
      border-radius: rem(8);
      box-shadow: 0 0 5px rgba(0,0,0,0.5);
      width: 40%;
    }

    &_title {
      width: 40%;
      overflow: hidden;
      justify-content: flex-end;
    }
  }
}