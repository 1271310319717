@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.advantages_container {
  margin-bottom: rem(20);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .advantage_container {
    margin-right: rem(4);
    margin-bottom: rem(4);

    &:last-child {
      margin-right: 0;
    }
  }

  .new-advantage {
    transition: 0.2s;
    cursor: pointer;
    width: rem(34);
    height: rem(34);
    background-color: #3F8CFE;
    border-radius: rem(4);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: map-get($color_secondary, "_1");
    }
  }
}

.loading-label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
}


