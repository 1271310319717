@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../header/styles.scss";
@import "../styles";

.img-container {
    margin-bottom: rem(12);
    justify-content: center;
    display: flex;
    flex-direction: column;
    >span {
        margin-top: rem(6);
        line-height: rem(26);
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: #4B5058;
    }
}