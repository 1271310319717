@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.block {
  padding: rem(20) 0;
  border-top: rem(1) solid rgba(0, 0, 0, 0.1);

  >.subtitle {
    margin-bottom: rem(12);

    >span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: #000000;
    }
  }

  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}