@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.order-view-container {
    padding: rem(24);
    display: flex;

    >div {
        display: flex;
        &:first-child {
            flex: 1;
            padding-right: rem(24);
            flex-direction: column;
        }

        &:last-child {
            flex:2;
            flex-direction: column;
            padding-left: rem(24);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.order-position {
    >td {
        padding: rem(8) 0;
    }
}

.order-view-loader {
    min-height: rem(450);
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-items-table {
    width: 100%;

    .additional {
        margin-left: 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #1B8737;

        &-label {
            color: map-get($color_gray, "_4");
        }
    }

    th {
        padding-bottom: rem(6);
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: map-get($color_gray, "_4");
        text-align: right;

        &:first-child {
            text-align: left;
        }
    }

    td {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: rem(16);
        line-height: rem(24);
        color: #000000;
        text-align: right;

        &:first-child {
            text-align: left;
        }
    }
}