@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../../styles/breakpoints.scss";

.review-holder-container {
  width: 100%;
  min-height: rem(400);
}
p {
  font-size: rem(12);
  font-weight: 400;
  color: map-get($color_gray, "_4");
}
.react-images__positioner {
  z-index: 999999 !important;
}
.root {
  box-sizing: border-box;
  padding: 0 rem(24) rem(24) rem(24);

  .basic {
    aside {
      padding: rem(16) 0;
      border-bottom: rem(1) solid map-get($color_gray, "_0");
      h3 {
        font-size: rem(20);
        font-weight: 500;
        margin-bottom: rem(4);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        font-size: rem(14);
        font-weight: 400;
        color: map-get($color_gray, "_4");
      }

      .contacts-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
      }
    }

    .description {
      min-height: rem(58);
      >p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: map-get($color-black, "_1");
      }
    }

    .photos {
      display: flex;
      border-bottom: none;
      height: rem(132);
      >div {
        display: flex;
        gap: rem(30);
        >div {
        display: flex;
        gap: rem(20);
          img {
            background-color: map-get($color_gray, "_0");
            border: rem(1) solid  map-get($color_gray, "_0");
            width: rem(100);
            height: rem(100);
            margin: 0 !important;
          }
        }
      }
    }
  }

  .bottom {
    position: sticky;
    bottom: 0;
    padding-top: rem(24);
    padding-bottom: rem(24);
    margin-bottom: rem(-24);
    background: map-get($color_white, "_0");
    border-top: rem(1) solid map-get($color_gray, '_1');
    margin-left: rem(-24);
    margin-right: rem(-24);
    padding-left: rem(24);
    padding-right: rem(24);
  }
}

.review-loader {
  height: rem(450);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}