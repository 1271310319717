@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.main-tabs {
  width: 100%;
  height: rem(48);
  display: flex;
  flex-direction: row;
  margin-bottom: rem(20);

  >button {
    border: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #4B5058;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-left: 0;
    border-right: 0;
    background-color: map-get($color_white, '_0');

    >span {
      color: map-get($color_black, "_1");
    }
  }

  .active {
    color: map-get($color_white, "_0");
    background-color: map-get($color_secondary, "_2");
  }
}