@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";


.categories-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50rem;
  max-height: 70rem;
  justify-content: flex-start;
  align-items: center;
}

.dashboard-category {
  display: flex;
  width: 100%;
  min-height: 15rem;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  &-title {
      color: map-get($color_gray, "_4");
      font-family: Roboto;
      font-style: bold;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 1rem;
  }

  &-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    height: max-content;
  }

  &-item {
    width: 25%;
    display: flex;
    height: 15rem;
    flex-direction: column;
    border-radius: rem(6);
    background-color: white;
    box-shadow: 0 15px 45px rgba(0, 0, 0, .1);
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    @media (max-width: $lg) {
      width: 40%;
    }
    @media (max-width: $md) {
      width: 45%;
    }
    @media (max-width: $sm) {
      width: 46%;
    }

    &:hover {
      background: map-get($color_gray, '_0');
    }

    &-text span {
    color: map-get($color_basic, "_0")!important;
    font-size: 18px!important;
    padding: 0 rem(8)!important;
    }
  }
}