@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.coupon-view-container {
    padding: rem(24);
    display: flex;

    >div {
        display: flex;
        &:first-child {
            flex: 3;
            padding-right: rem(24);
            flex-direction: column;
        }

        &:last-child {
            flex:5;
            flex-direction: column;
            padding-left: rem(24);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    

        .block {
            padding: rem(20) 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            >.subtitle {
                margin-bottom: rem(16);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            >.picture {
                width: 100%;

                >img {
                    width: 100%;
                }
            }


            >.data {
                margin-top: rem(-8);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            >table {
                width: 100%;
                border-collapse: inherit;
                border-spacing: 0 10px;
                tbody {
                    >tr {
                        border-top: 1px solid rgba(0, 0, 0, 0.1);

                        &:first-child {
                            border-top: none;
                        }

                        >td {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(0, 0, 0, 0.8);

                            &:first-child { 
                                text-align: left;
                                vertical-align: middle;
                            }

                            &:last-child {
                                text-align: right;
                                vertical-align: middle;
                                

                                >.number {
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: rem(4);
                                    width: rem(34);
                                    height: rem(36);
                                    width: 33px;
                                    background: #F5F5F5;
                                }
                            }
                        }
                    }

                }

                th {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.4);

                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }


            }

            &:first-child {
                padding-top: 0;
                border-top: none;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

.coupon-view-loader {
    height: rem(500);
    display: flex;
    align-items: center;
    justify-content: center;
}

