@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.week-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  color: map-get($color_gray, "_4");
  font-weight: 400;
  width: 100%;
  border-radius: rem(4);
  background-color: map-get($color_white, "_0");

  .buttons {
    margin-bottom: rem(16);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: rem(10);
  }

  >table {
    table-layout: auto;
    >tr {
      >td {
        padding: rem(10) 0;
        &:nth-child(1) {
          padding: rem(14) 0;
          font-size: rem(16);
          max-width: rem(70);
        }

        &:nth-child(2) {
          width: fit-content;
          text-align:right;
          max-width: rem(190);
          >div {
            width: fit-content;
            display: inline-flex;
            gap: rem(8);
            >.dash {
              display: flex;
              align-items: center;
            }
          }
        }
       
      }
    }
    .universal {
      padding-top: rem(16);
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: calc(100% - rem(42));
    background-color: map-get($color_white, "_0");
  }
}