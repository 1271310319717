@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.selected {
    display: flex;
    flex-direction: column;
    gap: rem(28);
    .cross {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    >div {
      display: flex;
      flex-direction: row;
      gap: rem(10);
      &:first-child {
        margin-top: rem(20);
      }
    }
  }

