@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.wk-receipt-body {
  background-color: white;
  border: 2px solid black;
  margin: 0;
  font-size: 0.875rem;
  vertical-align: baseline;
  width: rem(303);
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  letter-spacing: 0.5px;
  font-size: 12px;
  overflow-y: scroll;
  height: 68%;

  p {
    color: black;
    margin: 0;
    line-height: 18px;
  }
}

.wk-receipt-body::-webkit-scrollbar {
  width: 0;
}

.receipt-wrapper {
  width: 100%;
}

.wk-title {
  margin: 0;
  display: block;
  width: 100%;
  line-height: 18px;
}

.receipt-header {
    width: 100%;
}

.left-sided-item {
  text-align: left;
  word-wrap: break-word;
}
.column-block {
    display: flex;
    justify-content: space-between;
}
.qr-code {
    width: 60%;
}
.item-name {
    padding-left: 10px;
}
.item-count,
.item-cost {
    padding-left: 20px;
}