@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.stoplist-selected {
    margin-top: rem(10);
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - rem(360));
    overflow-y: auto;
    gap: rem(24);
    .cross {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    >div {
      display: flex;
      flex-direction: row;
      gap: rem(8);
    }
  }

