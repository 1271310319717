@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "src/main/styles/mixins";
@import "../styles";

.feedbacks {
  max-width: 100%;

  &_heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: rem(1) solid map-get($color_gray, "_1");
    padding-bottom: rem(12);
    margin-bottom: rem(24);

    @media (max-width: $sm) {
      flex-direction: column;
    }

    & h1 {
      margin-bottom: rem(16);
    }

    &_subheading {
      color: map-get($color_secondary, "_0");
    }

    &_users {
      color: map-get($color_gray, "_4");
    }
  }

  &_button {
    @media (max-width: $sm) {
      margin-top: rem(16);
    }
  }

  &_table {
    width: 100%;

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_1");

      & th {
        text-align: start;
        padding: rem(20) rem(16);
        font-weight: 400;
      }
    }

    &_body {
      border-bottom: rem(1) solid map-get($color_gray, "_1");

      &:last-child {
        border-bottom: none;
      }

      & td {
        text-align: start;
        padding: rem(16);
        word-wrap: break-word;
        vertical-align: middle;
        display: table-cell;
        min-width: rem(160);
      }
    }

    & &_edit {
      width: 10%;
      min-width: 110px;
      cursor: pointer;

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    &_iconWrapper {
      display: flex;
      cursor: pointer;
      @include simple-circle;

      &:hover {
        @include circle-hover;
        svg {
          fill: map-get($color_basic, '_0');
        }
      }
    }
  }

  &_modal {
    padding: rem(20);

    &_input {
      margin-bottom: rem(24);
    }

    &_button {
      width: 100%;
    }
  }
}
