@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

#--head-root {
  margin-bottom: rem(24);
  padding-bottom: rem(12);
  //border-bottom: rem(1) solid map-get($color_gray, "_1");
  .buttons {
    display: flex;
    width: auto;
    justify-content: flex-end;
    gap: rem(12);
  }
  .skeleton {
    transition: 600s;
  }
  > div:first-child {
    display: flex;
    justify-content: space-between;
    gap: rem(16);
    margin-bottom: rem(16);
    .head-title {
      display: flex;
      flex-direction: row;
      >h1 {
        font-size: rem(26);
        font-weight: 500;
        line-height: rem(38);
        margin: 0;
      }
    }
    

    @media (max-width: $sm) {
      display: block;

      h1 {
        margin-bottom: rem(12);
      }
    }
  }

  .breadcrumbs {
    a, span {
      font-size: rem(14);
    }

    .current {
      color: map-get($color_basic, "_1");
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
    }

    .separator {
      color: map-get($color_gray, "_4");
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      margin: 0 rem(8);
    }

    .past {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      text-decoration: none;
      color: map-get($color_gray, "_4");
    }
  }
}
