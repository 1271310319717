@import "../../../styles/rem.scss";

.advantages-selector {
    padding: rem(16);
    display: flex;
    flex-direction: column;
    gap: rem(8);
}



