@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.sidebar-row-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.1s;
  padding: rem(10) 0;
  padding-right: rem(28);
  padding-left: rem(28);
  justify-content: space-between;
  position: relative;

  &[data-active="true"] {
    background: map-get($color_gray, "_0");
  }

  &:hover {
    background: map-get($color_gray, "_0");
  }

  span {
    color: map-get($color_basic, "_0");
    font-size: rem(14);
    padding: 0 rem(8);
  }

  .row {
    display: flex;
    align-items: center;
  }

  .analytics-box {
    position: absolute;
    right: rem(28);
    background-color: map-get($color_black, "_2");
    height: rem(26);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(4);

    span {
      padding: rem(8);
    }
  }
}
