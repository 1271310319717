@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "src/main/styles/mixins";
@import "../styles";

.roles {
  &_card {
    background-color: map-get($color_white, "_0");
    height: fit-content;
    border-radius: rem(4);
    margin: rem(16) 0;

    &_title {
      text-align: center;
      margin-bottom: rem(8);
    }

    &_access {
      display: flex;
      align-items: center;
      margin-bottom: rem(8);

      &:last-of-type {
        margin-bottom: 0;
      }

      &:before {
        content: ' ';
        width: rem(6);
        height: rem(6);
        background-color: map-get($color_basic, "_1");
        border-radius: 50%;
        margin-bottom: rem(2);
        margin-right: rem(6)
      }

      & p {
        font-size: 16px;
      }
    }

    &_minus {
      width: rem(20);
      height: rem(20);
      background-color: map-get($color_white, "_1");
      border-radius: rem(2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rem(8);
      cursor: pointer;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &_table {
    width: 100%;

    &[disabled] {
      pointer-events: none;
      opacity: 0.6;
    }

    &_role {
      font-weight: 400;
    }

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_1");

      :nth-child(1){
        min-width: rem(150);
        width: 30%;
      }

      :nth-child(2) {
        min-width: rem(160);
        width: 50%;
      }

      & th {
        text-align: start;
        font-weight: 400;
        padding: rem(16);
      }
    }

    &_body {
      cursor: pointer;

      &_row {
        border-bottom: rem(1) solid map-get($color_gray, "_1");
      }

      & td {
        text-align: start;
        padding: rem(16);
        word-wrap: break-word;
        vertical-align: middle;
        display: table-cell;
      }
    }

    &_edit {
      width: 10%;
      min-width: rem(130);

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    &_iconWrapper {
      display: flex;
      cursor: pointer;
      @include simple-circle;

      &:hover {
        svg {
          fill: map-get($color_basic, '_0');
        }
        @include circle-hover;
      }
    }

    &_permissions {
      display: flex;
      width: 100%;
      margin-top: rem(-16);

      &_td {
        width: 100%;
      }

      &_item {
        width: 25%;
        min-width: rem(220);
      }

      &_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: rem(200);
      }

      &_title {
        margin-bottom: rem(8);
        font-weight: 400;
        margin-top: rem(16);
      }

      &_hidden {

        & td {
          padding: 0;
        }

        & div {
          height: 0;
          overflow: hidden;
        }
      }
    }

    &_arrow {
      transition: 0.3s all;
      display: flex;

      &[data-active="true"] {
        transform: rotate(180deg);
      }
    }
  }

  &_modal {

    &_input {
      margin-bottom: 16px;
    }

    &_button {
      width: 100%;
      margin-top: rem(8);
    }
  }
}
