@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.close-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: map-get($color_white, "_0");
    border-radius: rem(8);

  .close-title {
    flex: 1 1 50%;
    font-size: rem(20);
    font-weight: 500;
    color: map-get($color_basic, '_0')
  }

  .wrapper {
    justify-content: flex-end;
    flex-direction: row;
    display: flex;
    grid-gap: rem(12);
    gap: rem(12);

    .button-aggregator-on {
      outline: none;
      border: none;
      background-color: map-get($color_primary, '_0');
      border-radius: rem(4);
      width: fit-content;
      font-size: rem(17);
      padding: rem(8) rem(16);
      color: map-get($color_white, '_0');
    }

    .button-aggregator-off {
      outline: none;
      border: none;
      background-color: map-get($color_primary, '_0');
      border-radius: rem(4);
      width: fit-content;
      font-size: rem(17);
      padding: rem(8) rem(16);
      color: map-get($color_white, '_0');
    }

    .button-aggregator-disabled {
      outline: none;
      border: none;
      background-color: map-get($color_gray, '_3');
      border-radius: rem(4);
      width: fit-content;
      font-size: rem(17);
      padding: rem(8) rem(16);
      color: map-get($color_gray, '_5');
      cursor: not-allowed;
    }
  }
}
