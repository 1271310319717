@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.custom-time {
   width: rem(74);
   height: rem(38);
   display: flex;
   justify-content: center;
   align-items: center;
   border: rem(1) solid map-get($color_gray, "_1");
   border-radius: rem(4);
   cursor: pointer;
   span {
      color: rgba(0, 0, 0, 0.8);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: normal;
   }
   &:hover {
      transition: background-color 0.2s ease;
      background-color: map-get($color_gray, "_0");
   }
   input {
      cursor: pointer;
   }

}

.disabled {
   pointer-events: none;
   opacity: 0.4;
}