@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.receipt-ingredients-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: rem(12);

  p {
    font-family: 'Roboto';
    font-size: rem(12);
    line-height: rem(20);
    color: map-get($color_black, "_3");
    font-weight: 400;
  }

  .receipt-ingredients {
    display: flex;
    justify-content: space-between;

    p {
      font-family: 'Roboto';
      font-size: rem(12);
      line-height: rem(20);
      color: map-get($color_black, "_3");
      font-weight: 400;
    }
  }
}
