@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.input_mask {
  position: relative;
  width: 100%;

  &_mask {
    border: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    width: 90%;
    z-index: 2;
  }

  &_input {
    z-index: 10;

    &_focus {
      z-index: 1;
    }
  }
}
