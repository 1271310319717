@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "src/main/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 62px);
  overflow: hidden;

  .left {
    width: 50%;
    margin: rem(10) rem(20) rem(5) rem(20);
    overflow-y: overlay;
  }

  .left::-webkit-scrollbar {
    width: 0;
  }

  .right {
    width: 50%;
    height: 100vh;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.restaurants {
  &_card {
    background-color: map-get($color_white, "_0");
    height: fit-content;
    border-radius: rem(4);
    margin: rem(16) 0;

    &_title {
      text-align: center;
      margin-bottom: rem(8);
    }
  }

  &_table {
    width: 100%;

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_1");
      width: 100%;

      & th {
        text-align: start;
        font-weight: 400;
        padding: rem(16);
      }
    }

    &_body {
      cursor: pointer;
      border-bottom: rem(1) solid map-get($color_gray, "_1");

      & td {
        text-align: start;
        padding: rem(16);
        word-wrap: break-word;
        vertical-align: middle;
        display: table-cell;
      }
    }

    &_edit {
      width: 10%;
      min-width: rem(130);

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    &_iconWrapper {
      display: flex;
      cursor: pointer;
      @include simple-circle;

      &:hover {
        @include circle-hover;
        svg {
          fill: map-get($color_basic, '_0');
        }
      }
    }

    &_timetable {
      width: 100%;
      margin-top: rem(-16);

      &_service {
        font-weight: 400;
      }

      &_wrapper {
        display: flex;
      }

      &_td {
        width: 100%;
      }

      &_item {
        width: 20%;
        min-width: rem(220);
      }

      &_title {
        margin-bottom: rem(8);
      }
    }

    &_arrow {
      transition: 0.3s all;
      display: flex;

      &[data-open="true"] {
        transform: rotate(180deg);
      }
    }
  }
}

.title {
  font-family: 'Flame';
  font-size: rem(24);
  color: map-get($color_brown, "_0");
}

.content {
  padding-top: rem(45);
}

.hl {
  border: rem(0.25) solid map-get($color_brown, "_2");
  border-radius: rem(4);
  margin-top: rem(20);
}

.summary_table {
}

.aggregators_summary_table_heading {
  border-bottom: rem(1) solid map-get($color_gray, "_0");

  & th {
    text-align: start;
    padding: rem(20) rem(16);
    color: map-get($color_brown, "_0");
    font-weight: 400;
    font-family: "Flame";
  }

  &_sort {
    opacity: 0;
    color: map-get($color_gray, "_3");
    font-weight: 400;
    margin-left: rem(8);
    cursor: pointer;
    transition: 0.3s all;

    &_active {
      opacity: 1;
    }
  }
}

.aggregators_summary_table_body {
  cursor: default !important;

  &:hover {
    background-color: white;
  }

  & td {
    color: map-get($color_brown, "_1");
    font-weight: 400;
    font-family: "Flame";
  }
}
