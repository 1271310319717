@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../header/styles.scss";

.rest-drawer {
  background-color: map-get($color_white, "_0");
  width: 100%;
  height: 100%;
  margin-left: auto;

  .description {
    padding: 0 rem(20) 0 rem(20);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;

    .form-description {
      height: 100%;
      position: relative;
      width: 100%;
    }

    .header {
      height: rem(72);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: rem(1) solid map-get($color_yellow, "_3");

      .button-none {
        border: 0;
        background: transparent;
      }

      .cancel {
        margin-left: rem(20);
      }
    }

    .body {
      max-height: calc(100% - 157px);
      margin-bottom: rem(40);
      overflow-y: scroll;
      padding-bottom: rem(70);

      &::-webkit-scrollbar {
        display: none;
      }

    &-container {
      display: flex;
      flex-direction: column;
      padding: rem(16) 0 rem(16) 0;
      border-bottom: rem(1) solid map-get($color_yellow, "_3");

      &:nth-child(2n){
        border-bottom: 0;
      }

      .subtitle-wrapper {
        margin-bottom: rem(16);
        display: flex;
        align-items: center;

        .subtitle {
          font-family: 'Flame';
          font-style: normal;
          font-weight: normal;
          font-size: rem(24);
          color: map-get($color_brown, "_0");
        }
      }

      .sub-container {
        margin-bottom: rem(16);

        &:last-child {
          margin-bottom: 0;
        }

        .sub-subtitle-wrapper {
          margin-bottom: rem(12);
          display: flex;
          align-items: center;

          .sub-subtitle {
            font-family: 'Flame';
            font-style: normal;
            font-weight: normal;
            font-size: rem(20);
            color: map-get($color_brown, "_0");
          }
        }

        .sub-time {
          font-family: 'Rotonda';
          font-size: rem(20);
          color: map-get($color_brown, "_0");
        }
      }
    }

    .summary-container {
      margin-bottom: rem(20);
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .price-wrapper {
        .price {
          font-family: 'Flame';
          font-style: normal;
          font-weight: normal;
          font-size: rem(20);
          color: map-get($color_brown, "_0");
        }
      }
    }
    }

      .footer-restaurant {
        height: rem(95);
        display: flex;
        align-items: center;
        position: sticky;
        bottom: 0;
        margin-top: 100%;
        background: map-get($color_white, "_0");
      }
  }
}
