@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "src/main/styles/mixins";

.title-el {
  font-family: 'Flame';
  font-size: rem(20);
  color: map-get($color_brown, "_0");
  padding-top: rem(16);
  padding-bottom: rem(12);
}

.filter-wrapper {
  display: flex;

  .filter-clear-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: rem(4);
  }
}

.modal-search-icon {
  display: flex;
  left: 0.875rem;
  position: relative;
}

.pickers-wrapper {
  display: flex;
  justify-content: space-between;
}

.button-model-apply {
  padding-top: rem(16);
}

.restaurants {
  &_card {
    background-color: map-get($color_white, "_0");
    height: fit-content;
    border-radius: rem(4);
    margin: rem(16) 0;

    &_title {
      text-align: center;
      margin-bottom: rem(8);
    }
  }

  &_table {
    width: 100%;

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_1");
      width: 100%;

      & th {
        text-align: start;
        font-weight: 400;
        padding: rem(16);
      }
    }

    &_body {
      cursor: pointer;
      border-bottom: rem(1) solid map-get($color_gray, "_1");

      & td {
        text-align: start;
        padding: rem(16);
        word-wrap: break-word;
        vertical-align: middle;
        display: table-cell;
      }
    }

    &_edit {
      width: 10%;
      min-width: rem(130);

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    &_iconWrapper {
      display: flex;
      cursor: pointer;
      @include simple-circle;

      &:hover {
        @include circle-hover;
        svg {
          fill: map-get($color_basic, '_0');
        }
      }
    }

    &_timetable {
      width: 100%;
      margin-top: rem(-16);

      &_service {
        font-weight: 400;
      }

      &_wrapper {
        display: flex;
      }

      &_td {
        width: 100%;
      }

      &_item {
        width: 20%;
        min-width: rem(220);
      }

      &_title {
        margin-bottom: rem(8);
      }
    }

    &_arrow {
      transition: 0.3s all;
      display: flex;

      &[data-open="true"] {
        transform: rotate(180deg);
      }
    }
  }
}

.title {
  font-family: 'Flame';
  font-size: rem(28);
  color: map-get($color_brown, "_0");
}

.sort-wrapper-archive {
  display: inline-flex;
  padding-top: rem(16);
  padding-bottom: rem(16);
  cursor: pointer;

  .sort-title {
    font-family: 'Rotonda';
    font-size: rem(20);
    color: map-get($color_gray, "_9");
    padding-left: rem(4);
  }
}
