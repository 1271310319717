@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.input-ip-container {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  background: map-get($color_white, "_0");
  border: 0;
  border-radius: rem(4);

  .complete {
    position: absolute;
    opacity: 0.5;
    padding-left: rem(12);
    padding-right: rem(32);
  }

  input {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: rem(14);
    color: map-get($color_black, "_0");
    width: 100%;
    border: none;
    background-color: transparent;
    transition: 0.2s;
    outline: none;
    padding: 0;
    padding-left: rem(12);
    padding-right: rem(32);
    z-index: 2;
    height: 100%;

    &.password {
      letter-spacing: rem(4);
      font-weight: 900;
      font-size: rem(18);
    }

    &:focus {
      outline: none;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      top: rem(-10);
      font-size: rem(12);
      background: map-get($color_white, "_0");
      padding: 0 rem(4);
      left: rem(8);
    }
  }

  label {
    position: absolute;
    z-index: 1;
    top: rem(11);
    left: 0;
    font-weight: 300;
    font-size: rem(13);
    left: rem(12);
    transition: 0.2s;
    border-radius: rem(4);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .is-danger {
    display: flex;
    position: absolute;
    right: rem(10)
  }

  > .is-valid {
    display: flex;
    position: absolute;
    right: rem(12)
  }

  .right-icon {
    display: flex;
    right: rem(10);
    position: relative;
  }

  &.default {
    border: rem(1) solid map-get($color_gray, "_1");

    > label {
      color: map-get($color_gray, "_4");
    }
  }

  &.primary {
    border: rem(1) solid map-get($color_gray, "_4");

    > label {
      color: map-get($color_gray, "_4");
    }
  }

  &.secondary {
    border: rem(1) solid map-get($color_green, "_0");

    > label {
      color: map-get($color_green, "_0");
    }
  }

  &.danger {
    border: rem(1) solid map-get($color_red, "_0");

    > label  {
      color: map-get($color_red, "_0");
    }
  }

  &[disabled] {
    border: rem(1) solid map-get($color_gray, "_4");

    > label  {
      color: map-get($color_gray, "_4");
    }

    input {
      pointer-events: none;
      color: map-get($color_gray, "_4");
    }
  }

  &.small {
    height: rem(34);

    > label {
      top: rem(8);
    }
  }

  &.medium {
    height: rem(38);

    > label {
      top: rem(10);
    }
  }

  &.large {
    height: rem(42);

    > label {
      top: rem(12);
    }
  }
}
