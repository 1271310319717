@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: rem(16);
  justify-content: space-between;

  .wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .info-wrapper-element {
    flex: 1;
    display: flex;
  }

  .image-container {
    display: flex;
    align-items: flex-start;
    margin-right: rem(8);

    .image {
      width: rem(44);
      height: rem(44);
      background-color: map-get($color_brown, "_0");
    }
  }

  .info-container {
    width: 100%;
    min-width: rem(200);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

      .title-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .title {
          display: block;
          font-family: "Rotonda";
          font-style: normal;
          font-weight: normal;
          font-size: rem(20);
          color: map-get($color_brown, "_0");
        }
      }

      .top-12 {
        margin-top: rem(12);
      }

      .details-container {
        display: flex;
        flex-direction: column;

        .detail-title {
          font-family: "Flame";
          font-size: rem(18);
          padding-top: rem(12);
          padding-bottom: rem(4)
        }

        .add {
          color: map-get($color_green, "_5");
        }

        .remove {
          color: map-get($color_red, "_4");
        }

        .top-4 {
          padding-top: rem(4);
        }
                      
        .detail-wrapper {
            .detail {
            font-family: "Rotonda";
            font-style: normal;
            font-weight: normal;
            font-size: rem(18);
            color: #9F816F;
            }

            .detail-side {
              font-family: "Rotonda";
              font-style: normal;
              font-weight: normal;
              font-size: rem(18);
              color: map-get($color_brown, "_0");
            }
        }
      }
  }

  .price-container {
    display: flex;
    justify-self: end;

    .price {
      font-family: 'Flame';
      font-style: normal;
      font-weight: normal;
      font-size: rem(20);
      color: map-get($color_brown, "_0");
    }
  }
}
