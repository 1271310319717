@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.dropdown {
  width: 100%;
  max-width: 400px;

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_open {
      margin-bottom: rem(8);
    }
  }

  &_children {
    margin-left: rem(16);
  }

  &_arrow {
    transition: 0.3s all;

    &_open {
      transform: rotate(180deg);
      transition: 0.3s all;
    }
  }
}
