@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.checkbox {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  align-items: flex-start;

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &::after {
    color: inherit;
    background-color: transparent;
  }

  .label {
    position: relative;
    top: rem(11);
    margin-bottom: rem(14);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4B5058;
  }
}