@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

@mixin scrollbars() {

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: .3s ease;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    color: rgba(0, 0, 0, 0.1);

    &:hover {
      color: rgba(0, 0, 0, 0.2);
      transition: color .6s ease;
    }
  }
}

.definition {
  @include scrollbars();
  width: 100%;

  >.suggestions {
    margin-top: rem(16);
    display: block;
    width: rem(504);
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    position: absolute;
    z-index: 3;
    background: map-get($color_white, "_0");
    border-radius: rem(4);
    box-shadow: 0px 4px 8px 6px rgb(0 0 0 / 15%);

    .suggestion {
      cursor: pointer;
      height: rem(44);
      display: flex;
      padding: 0 rem(12) 0 rem(12);
      align-items: center;
      background-color: #fff;

      &-label-blue {
        color: map-get($color_primary, _0);
        display: flex;
        align-items: center;
        gap: rem(6);
      }

      &:last-child {
        border-radius: 0 0 rem(4) rem(4);
      }

      &:first-child {
        border-radius: rem(4) rem(4) 0 0;
      }

      &:hover {
        background-color: map-get($color_gray, _0);
      }
    }
  }

  .choosed {
    width: 100%;
    margin-top: rem(12);
    display: flex;
    flex-direction: column;
    gap: rem(12);

    >div {
      >span {
        &:first-child {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
          opacity: 0.4;
        }
  
        &:last-child {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
      display: flex;
      justify-content: flex-start;
      gap: rem(8);
    }
  }
}