@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.w-loader {
  display: flex;
  justify-content: center;
}

.w-loader-center {
  display: flex;
}
