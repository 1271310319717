@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.square-button {
  background: map-get($color_primary, "_0");
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(4);
  outline: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: 0.2s;
  box-sizing: border-box;

  &.default {
    background-color: map-get($color_gray, "_1");

    &:hover {
      background-color: map-get($color_white, "_4");
    }

    &.outline {
      background: map-get($color_white, "_0");
      border: rem(1) solid map-get($color_black, "_2");

      &:hover {
        background-color: map-get($color_gray, "_0");
      }
    }
  }

  &.primary {
    background-color: map-get($color_basic, "_1");
    transition: 0.2s;

    svg, span {
      fill: map-get($color_white, "_0");
      color: map-get($color_white, "_0");
      transition: 0.2s;
    }

    &:hover {
      background-color: map-get($color_secondary, "_1");
    }

    &.outline {
      background: map-get($color_white, "_0");
      border: rem(1) solid map-get($color_basic, "_1");

      &:hover {
        border: rem(1) solid map-get($color_secondary, "_1");
        background-color: map-get($color_white, "_3");

        svg, span {
          color: map-get($color_basic, "_1");
        }
      }

      svg, span {
        color: map-get($color_basic, "_1");
        fill: map-get($color_basic, "_1");
      }
    }
  }

  &.secondary {
    background-color: map-get($color_green, "_0");

    svg, span {
      color: map-get($color_white, "_0");
      transition: 0.2s;
      fill: map-get($color_white, "_0");
    }

    &:hover {
      background-color: map-get($color_green, "_4");
    }

    &.outline {
      background: map-get($color_white, "_0");
      border: rem(1) solid map-get($color_green, "_0");

      svg,
      span {
        color: map-get($color_green, "_0");
        fill: map-get($color_green, "_0");
      }

      &:hover {
        background-color: map-get($color_green, "_2");
        border: rem(1) solid map-get($color_green, "_3");
      }
    }
  }

  &.danger {
    background-color: map-get($color_red, "_0");

    svg, span {
      color: map-get($color_white, "_0");
      fill: map-get($color_white, "_0");
    }

    &.outline {
      background: map-get($color_white, "_0");
      border: rem(1) solid map-get($color_red, "_1");

      svg, span {
        color: map-get($color_red, "_0");
        fill: map-get($color_red, "_0");
      }

      &:hover {
        background-color: map-get($color_red, "_3");
        border: rem(1) solid map-get($color_red, "_1");
      }
    }
  }

  &[disabled] {
    pointer-events: none;
    background-color: map-get($color_gray, "_2");

    > svg {
      color: map-get($color_gray, "_5");
    }

    &.outline {
      background: map-get($color_white, "_0");
      border: rem(1) solid map-get($color_gray, "_2");
    }
  }

  &.small {
    height: rem(32);
    width: rem(32);

    svg {
      width: rem(12);
      height: rem(12);
    }
  }

  &.medium {
    height: rem(34);
    width: rem(34);

    svg {
      width: rem(16);
      height: rem(16);
    }
  }

  &.large {
    height: rem(40);
    width: rem(40);

    svg {
      width: rem(20);
      height: rem(20);
    }
  }

  &.circle {
    border-radius: rem(50);
  }
}
