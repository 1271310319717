@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

@mixin scrollbars() {

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: .3s ease;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    color: rgba(0, 0, 0, 0.1);

    &:hover {
      color: rgba(0, 0, 0, 0.2);
      transition: color .6s ease;
    }
  }
}

.map {
  min-height: rem(500);
}; 

  .search-input {
    position: absolute;
    top: rem(90);
    left: rem(24);
    width: rem(350);
    @include scrollbars();

    &-suggestions {
      margin-top: rem(16);
      display: block;
      width: rem(350);
      max-height: rem(200);
      overflow-y: auto;
      position: absolute;
      z-index: 3;
      background: map-get($color_white, "_0");
      border-radius: rem(4);
      box-shadow: 0px 4px 8px 6px rgb(0 0 0 / 15%);

      >div {
        cursor: pointer;
        height: rem(44);
        display: flex;
        padding: 0 rem(12) 0 rem(12);
        align-items: center;
        background-color: #fff;

        &-label-blue {
          color: map-get($color_primary, _0);
          display: flex;
          align-items: center;
          gap: rem(6);
        }

        &:last-child {
          border-radius: 0 0 rem(4) rem(4);
        }

        &:first-child {
          border-radius: rem(4) rem(4) 0 0;
        }

        &:hover {
          background-color: map-get($color_gray, _0);
        }
      }
    }
  }

  .footer {
    border: rem(1) solid rgba(0, 0, 0, 0.1);
    width: 100%;
    height: rem(70);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 rem(20);
  }
