@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

#--auth-root {
  display: flex;

  .container {
    width: 57%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .auth {
      padding: rem(16);
    }
    @media (max-width: $lg) {
      width: 100%;
    }

    .auth-box {
      
      width: rem(400);
      margin-top: rem(12);

      h1 {
        font-size: rem(24);
        font-weight: 400;
        margin-bottom: rem(8);
      }

      @media (max-width: $sm) {
        min-width: auto;
        width: calc(100% - #{rem(48)});
      }

      &.disabled {
        pointer-events: none;
      }

      .fileds {
        margin-top: rem(16);

        > div {
          margin-bottom: rem(16);
        }
      }

      .enter {
        margin-top: rem(18);

        div,
        button {
          width: 100%;
        }
      }

      & ~ #--auth-languages {
        margin-top: rem(12);
        height: fit-content;
        display: flex;
        width: rem(368);
        gap: rem(8);
        justify-content: center;


        .language-box {
          height: fit-content;
          background-color: transparent;
          border: none;
          outline: none;
          display: flex;
          padding: rem(12);
          border-radius: rem(50);
          transition: 0.2s;
          cursor: pointer;
          

          &:hover {
            background-color: map-get($color_gray, "_0");
          }

          .language-basic {
            background-size: 160%;
            background-position: center;
            border-radius: rem(20);
            width: rem(26);
            height: rem(26);
            opacity: 0.3;
            transition: 0.2s;
          }

          .lang-selected {
            opacity: 1;
          }
        }

        .rus {
          background-image: url('../../assets/images/russia.png');
        }

        .eng {
          background-image: url('../../assets/images/uk.png');
        }

        .kaz {
          background-image: url('../../assets/images/kaz.jpg');
        }
      }
    }
  }

  .slider-root {
    width: 43%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media (max-width: $lg) {
      display: none;
    }

    .slider {
      figure {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position:absolute;
        width: 100%;
        transition: transform 15s linear, opacity 2s ease-in-out;
        opacity: 0;

        &:nth-child(1) {
          background-image: url('../../assets/images/burger1.jpeg');
        }

        &:nth-child(2) {
          background-image: url('../../assets/images/burger2.jpeg');
        }

        &:nth-child(3) {
          background-image: url('../../assets/images/burger3.jpeg');
        }

        &.show {
          z-index: 1;
          transform: scale(1.12);
          opacity: 1;
        }
      }
    }

    .gradient {
      position: relative;
      z-index: 1;
      height: 100%;
      display: flex;
      background: -webkit-linear-gradient(0deg, map-get($color_black, "_1") -20%, transparent 200%);
      background: -moz-linear-gradient(0deg, map-get($color_black, "_1") -20%, transparent 200%);
      background: linear-gradient(0deg, map-get($color_black, "_1") -20%, transparent 200%);

      > aside {
        max-width: rem(340);
        position: absolute;
        bottom: rem(130);
        left: rem(90);

        h1,
        p {
          color: map-get($color_white, "_0");
          opacity: 0.9;
        }

        p {
          margin-top: rem(10);
        }

        h1 {
          font-size: rem(48);
          line-height: rem(52);

          @media (max-width: $xl) {
            font-size: rem(44);
            line-height: rem(48);
          }
        }
      }
    }
  }
}
