@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.block-row {
    width: 100%;

    &:last-child {
        margin-bottom: 0;

    }

    >span {
         font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            word-break: break-all;
           
    }
    &-title {
            opacity: 0.4;
    }
}
.block-column-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.block-column-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    word-break: break-all;
}