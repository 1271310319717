@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.permissions-list {
  max-height: calc(100vh - 360px);
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.role-body {
  width: rem(500);
}
.role-footer {
  width: rem(500);
}