@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../header/styles.scss";

.drawer {
  background-color: map-get($color_white, "_0");
  width: 100%;
  height: 100%;
  margin-left: auto;
  display: flex;

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(150);

    .description-image {
      background-image: url('../../../../assets/images/burger4.png');
      width: rem(400);
      height: rem(280);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .description-choose {
      font-family: 'Flame';
      font-size: rem(28);
      color: map-get($color_brown, "_0");
      margin-top: rem(32);
      margin-bottom: rem(8);
    }

    .description-info {
      font-family: 'Rotonda';
      font-size: rem(20);
      color: map-get($color_brown, "_0");
      text-align: center;
      padding-inline: rem(50);
    }
  }
}

