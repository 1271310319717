@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "src/styles/mixins";
@import "../styles";

@mixin customScroll() {
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
        width: 26px;
        border-radius: 13px;
        background-clip: padding-box;
        border: 10px solid transparent;
        transition: .3s ease;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 10px;
        color: rgba(0, 0, 0, 0.1);

        &:hover {
            color: rgba(0, 0, 0, 0.2);
            transition: color .6s ease;
        }
    }
}

.spinner {
    display: flex;
    margin: 0 auto;
    align-self: center;
}

.round {
    vertical-align: middle;
    width: rem(20);
    height: rem(20);
    border-radius: 50%;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: rem(20);
    justify-items: center;

    .prop {
        display: flex;
        gap: rem(8);

        &-title {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: map-get($color-black, "_1");
            opacity: 0.4;
        }


        &-value {
            height: auto;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            overflow: hidden;
            word-wrap: break-word;
            font-size: 16px;
            line-height: 24px;
            color: map-get($color-black, "_1");
            max-width: 190px;

            &-short {
                align-items: center;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: map-get($color-black, "_1");
                max-width: 190px;
            }
        }
    }

    @include customScroll();

    .card {
        display: flex;
        flex-wrap: wrap;
        z-index: 2;
        width: rem(320);
        height: rem(308);
        border-radius: rem(6);
        background-color: white;
        box-shadow: 0 15px 45px rgba(0, 0, 0, .1);

        >div {
            display: flex;
        }

        &-photo {
            z-index: 2;
            width: 100%;
            max-width: rem(320);
            height: rem(140);
            border-radius: rem(8);
            background-color: white;

            >img {
                max-width: rem(320);
                z-index: 1;
                margin: 0 auto;
            }
        }

        &-header {
            width: 100%;
            padding: rem(2) rem(16) 0 rem(16);
            align-items: center;
            justify-content: space-between;
        }

        &-description {
            width: 100%;
            padding: 0 rem(16) 0 rem(16);
            max-height: rem(56);
            flex-direction: column;
            justify-content: flex-start;

            &-detailed {
                max-height: rem(192);
                padding: 0 rem(16) 0 rem(16);
                flex-direction: column;
                overflow: auto;
            }
        }

        &-footer {
            line-height: normal;
            align-self: flex-end;
            align-items: center;
            padding: rem(16);
            cursor: pointer;

            >.icon {
                &-down {
                    display: flex;
                }

                &-up {
                    display: flex;
                    transform: rotate(180deg);
                }
            }

            >span {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: normal;
                color: map-get($color-secondary, "_1");
            }
        }
    }
}

.story-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.story-empty {
    height: rem(150);
    width:  rem(450);
    margin: 0 auto;
    border-radius: rem(6);
    background-color: white;
    box-shadow: 0 15px 45px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.story-loading {
    width:  rem(450);
    height: rem(150);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}