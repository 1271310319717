@import "../../../styles/rem.scss";
@import "../../../styles/colors.scss";

.send-menu-modal {
    >body {
        width: rem(536);
        background: #fff;
        min-height: rem(500);
        padding: 8px 24px;

        >.checkbox {
            margin-left: rem(-12);
            margin-bottom: rem(8);
        }
    }

    >footer {
        width: rem(536);
        position: fixed;
        bottom: 0;
        height: rem(80);
        align-items: center;
        justify-content: space-between;
        display: flex;
        padding: 8px 24px;
        border-top: 1px solid map-get($color_gray, '_7');
        background-color: #fff;
    }

    >header {
        height: rem(80);
        align-items: center;
        display: flex;
        padding: 8px 24px;
        border-bottom: 1px solid map-get($color_gray, '_7');

        >h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 36px;
            color: #000000;
        }
    }
}