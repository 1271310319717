@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.modificator-view-container {
    padding: rem(24);
    display: flex;

    >div {
        &:first-child {
            width: rem(360);
            padding-right: rem(24);
        }

        &:last-child {
            width: calc(100% - rem(360));
            padding-left: rem(24);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }

        .block {
            padding: rem(20) 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            >.subtitle {
                margin-bottom: rem(16);

                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }

            >.picture {
                display: flex;
                justify-content: center;
                width: 100%;
                height: rem(220);
                > img{
                   width: fit-content;
                }
            }

            >.row {
                display: flex;
                gap: rem(8);
                margin-bottom: rem(12);

                &:last-child {
                    margin-bottom: 0;
                }

                >span {
                    &:first-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        opacity: 0.4;
                    }

                    &:last-child {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                    }
                }

                
            }
            
            >.data {
                margin-top: rem(-8);
                >span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }
            }
            >.divider {
                padding: rem(16) 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                    border-bottom: 0;
                    padding: rem(16) 0 0 0;
                }
                &:first-child {
                    padding-top: 0;
                }
                
                >.row-fullwidth {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: rem(12);

                    >span {
                        &:first-child {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                            opacity: 0.4;
                        }

                        &:last-child {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &:first-child {
                padding-top: 0;
                border-top: none;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}