@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

@mixin simple-circle {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  padding: rem(12);
  border-radius: rem(50);
  transition: 0.2s;
  cursor: pointer;
}

@mixin circle-hover {
  background-color: map-get($color_gray, "_1");
}

@mixin ru {
  background-image: url('../../../assets/images/russia.png');
}

@mixin en {
  background-image: url('../../../assets/images/uk.png');
}

@mixin kaz {
  background-image: url('../../../assets/images/kaz.jpg');
}

#--header-root {
  padding: rem(8) rem(20);
  max-height: rem(64);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: map-get($color_white, "_0");
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0 rem(2) rem(3) map-get($color_gray, "_6");

  @media (max-width: $lg) {
    padding: rem(8) rem(20);
  }

  @media (max-width: $sm) {
    padding: rem(8) rem(4);
  }

  .burger {
    button {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }

      @media (min-width: $lg) {
        display: none;
      }
    }
  }

  .content-header {
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: $lg) {
      justify-content: flex-end;
    }

    .search {
      display: flex;
      position: relative;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .language {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }

      .rus {
        @include ru;
      }

      .eng {
        @include en;
      }

      .kaz {
        @include kaz;
      }

      .language-image {
        background-size: 160%;
        background-position: center;
        border-radius: rem(20);
        width: rem(22);
        height: rem(22);
      }
    }

    .logout {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }
    }
  }
}
