@font-face {
    font-family: 'Flame';
    src: url('./fonts/FLAME-REGULAR.OTF');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rotonda';
    src: url('./fonts/ofont.ru_Rotonda.ttf');
    font-weight: 400;
    font-style: normal;
}