@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.modal-loader {
  width: rem(560);
  height: calc(100vh - rem(300));
  display: flex;
  justify-content: center;
  align-items: center;
}


@mixin customScroll() {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    transition: .3s ease;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    color: rgba(0, 0, 0, 0.1);

    &:hover {
      color: rgba(0, 0, 0, 0.2);
      transition: color .6s ease;
    }
  }
}

.modal {
  @include customScroll();
  height: 100%;

  .header {
    width: 100%;
    height: rem(100);
    border-bottom: 1px solid map-get($color_gray, '_7');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: rem(10);
    background-color: white;
    position: sticky;
    z-index: 10;
    top: 0;

    &-nosteps {
      width: 100%;
      height: rem(80);
      border-bottom: 1px solid map-get($color_gray, '_7');
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: rem(10);
      background-color: white;
      position: sticky;
      z-index: 10;
      top: 0;

      >div {
        margin-left: rem(28);
      }

      .title {
        >span {
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: rem(28);
          line-height: 36px;
          color: map-get($color_black, "_1");
        }
      }
    }

    >div {
      margin-left: rem(28);
    }

    .title {
      >span {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: rem(28);
        line-height: 36px;
        color: #000000;
      }
    }

    .step {
      >span {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .body {
    width: rem(560);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: rem(20) rem(28) rem(20) rem(28);

    .subtitle {
      width: 100%;
      padding-bottom: rem(16);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      >span {
        padding: 0;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }

    .container_fullwidth {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid map-get($color_gray, '_7');
      margin-bottom: rem(20);

      .error {
        margin-top: rem(4);

        >span {
          font-size: rem(12);
          color: map-get($color_red, '_4')
        }
      }
      .warning {
        margin-top: rem(4);

        >span {
          font-size: rem(12);
          color: map-get($color_yellow, '_0')
        }
      }

      .textButton {
        cursor: pointer;
        margin-top: rem(16);
        display: flex;
        align-items: center;
        gap: rem(6);

        >span {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &:last-child {
        border: 0;
        // margin-bottom: rem(20);

        // &:last-child {
        //   margin-bottom: rem(50);
        // }
      }

      &_half {
        margin-top: rem(16);
        width: rem(244);

        &_first {
          margin-top: 0;
          width: rem(244);
        }
      }

      &_third {
        margin-top: rem(16);
        width: rem(157);

        &_first {
          margin-top: 0;
          width: rem(157);
        }
      }

      &_one {
        margin-top: rem(16);
        width: 100%;

        &_first {
          margin-top: 0;
          width: 100%;
        }
      }

      .langs {
        margin-bottom: rem(20);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: rem(8);
      }

      >div {
        &:last-child {
          margin-bottom: rem(20);
        }
      }
    }
  }

  .footer {
    margin-top: rem(12);
    position: fixed;
    width: rem(560);
    height: rem(80);
    bottom: 0;
    padding: 0 rem(28) 0 rem(28);
    background-color: map-get($color_white, '_0');
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid map-get($color_gray, '_7');
    align-items: center;
    z-index: 3;
  }

  .wide {
    width: rem(1120);

    @media (max-width: rem(1220)) {
      width: rem(560);
    }
  }
}

.double-modal {
  @media (max-width: rem(1220)) {
    width: rem(560);
    margin-bottom: rem(80);
  }

  width: rem(1120);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.date-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .date-modal-title {
    font-size: rem(16);
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 16px;
  }

  & .date-modal-buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    & .button-submit {
      margin-left: 16px;
    }
  }

  & .date-modal-inner {
    background-color: white ;
    padding: 16px;
    height: fit-content;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > div {
      margin-bottom: 16px;
    }

    & .date-picker {
      width: 80%;
    }
  }
}

