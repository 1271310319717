@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

.container-aggregator {
  background-color: map-get($color_white, "_0");
    border-radius: rem(8);
    padding: rem(12);
    margin-top: rem(20);

  .aggregator-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .agg-name {
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_brown, '_0');
    }

    .dot-yellow {
      height: rem(4);
      width: rem(4);
      background-color: map-get($color_yellow, '_0');
      border-radius: 50%;
      align-self: center;
      margin-inline: rem(8);
    }

    .agg-info {
      font-family: 'Rotonda';
      font-size: rem(16);
      color: map-get($color_brown, '_3');
    }
  }

  .wrapper {
    justify-content: space-between;
    flex-direction: row;
    display: flex;

    .button-aggregator-on {
      outline: none;
      border: none;
      background-color: map-get($color_green, '_5');
      height: rem(56);
      border-radius: rem(8);
      width: 49%;
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_white, '_0');
    }

    .button-aggregator-off {
      outline: none;
      border: none;
      background-color: map-get($color_brown, '_0');
      height: rem(56);
      border-radius: rem(8);
      width: 49%;
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_white, '_0');
    }

    .button-aggregator-disabled {
      outline: none;
      border: none;
      background-color: map-get($color_gray, '_8');
      height: rem(56);
      border-radius: rem(8);
      width: 49%;
      font-family: 'Flame';
      font-size: rem(20);
      color: map-get($color_white, '_0');
      cursor: default;
    }
  }
}
