@import "breakpoints";
@import "rem";
@import "colors";

// Mixin for active/hover click elements.
@mixin click_elem {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.2s opacity;

    @media (max-width: $lg) {
      opacity: 1;
    }
  }
}

@mixin simple-circle {
  border: none;
  outline: none;
  display: flex;
  width: rem(40);
  height: rem(40);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;
  background-color: transparent;
}

@mixin circle-hover {
  background-color: map-get($color_gray, "_0");
}
