@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: rem(12);

  .dot {
    width: rem(4);
    height: rem(4);
    border-radius: 50%;

    &.active {
      background-color: map-get($color_green, "_0");
      border-color: map-get($color_green, "_0");;
    }
    &.created {
      background-color: blue;
      border-color: blue;
    }
    &.disabled {
      background-color: map-get($color_red, "_0");
      border-color: map-get($color_red, "_0");
    }
    &.ready {
      background-color: map-get($color_green, "_0");
      border-color: map-get($color_green, "_0");;
    }
    &.failed {
      background-color: map-get($color_red, "_0");
      border-color: map-get($color_red, "_0");
    }
    &.canceled {
      background-color: map-get($color_gray, "_5");
      border-color: map-get($color_gray, "_5");
    }
    &.picked {
      background-color: map-get($color_gray, "_5");
      border-color: map-get($color_gray, "_5");
    }
  }
  .status-wrapper {
    margin-left: rem(8);
    .status {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;

      &.active {
        color: #1B8737;
      }
      &.disabled {
        color: map-get($color_red, "_0");
      }
      &.created {
        color: blue;
      }
      &.ready {
        color: map-get($color_green, "_0");
      }
      &.failed {
        color: map-get($color_red, "_0");
      }
      &.canceled {
        color: map-get($color_gray, "_5");
      }
      &.picked {
        color: map-get($color_gray, "_5");
      }
    }
  }
}
  

 
  

