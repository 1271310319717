@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.paper {
  color: map-get($color_basic, "_0");
  background-color: map-get($color_white, "_0");
  overflow: hidden;
  box-shadow: map-get($color_gray, "_3") 0 rem(1) rem(3) rem(-1);
  border-radius: rem(6);
  //padding: rem(24);
  //padding-bottom: rem(24);

  &[disabled] {
    pointer-events: none;
  }

  &.shadow-disable {
    box-shadow: none;
  }
}
