@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.counter_container {
  width: 100%;

  &_search {
      width: 100%;

      &_selected {
        width: inherit;
        height: max-content;
        display: flex;
        flex-direction: column;
       
        &_selected {
          width: inherit;
          display: flex;
          height: rem(36);
          padding: rem(10) 0 rem(10) 0;
          align-items: center;
          
        .label {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.8)
        }
        
        &_delete {
          display: flex;
          cursor: pointer;
          align-items: center;
          margin-right: rem(11);
        }
          .control_container {
            display: flex;
            justify-content: space-between;
            background-color: map-get($color_gray, "_0");
            border-radius: rem(4);
            width: rem(81);
            padding: 0 rem(14)  0 rem(14);
            height: inherit;
            flex-direction: row;
            margin-left: auto;
            align-items: center;
        
            .increase_container {
              .increase_button {
                display: flex;
                justify-content: center; 
                align-items: center;
                background: transparent;
                border: 0;
                padding: 0;
                color: map-get($color_black, "_0");
              }
        
              .decrease_label {
                font-size: rem(18);
                align-items: center;
              }
            }
            .number_container {
                display: flex;
                justify-content: center; 
                align-items: center;
                background: transparent;
                border: 0;
                padding: 0;
                color: map-get($color_black, "_0");

                .decrease_label {
                    align-items: center;
                    font-size: rem(20);
                }
            }

            .decrease_container {
              .decrease_button {
                display: flex;
                justify-content: center; 
                align-items: center;
                background: transparent;
                border: 0;
                padding: 0;
                color: map-get($color_black, "_0");
                font-size: rem(18);
        
                .decrease_label {
                  font-size: rem(18);
                  align-items: center;
                }
              }
            }
          }
        }
      }
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: default;
}